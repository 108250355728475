export var BUILDING_LOADING_BUILDINGS = 'BUILDING_LOADING_BUILDINGS';
export var BUILDING_GET_BUILDING = 'BUILDING_GET_BUILDING';
export var BUILDING_RESET_FIELDS = 'BUILDING_RESET_FIELDS';
export var BUILDING_GET_COMPLEX = 'BUILDING_GET_COMPLEX';
export var BUILDING_GET_SIMPLE_ITEMS = 'BUILDING_GET_SIMPLE_ITEMS';
export var BUILDING_GET_INPUT_GROUP_EQUIPMENT = 'BUILDING_GET_INPUT_GROUP_EQUIPMENT';
export var BUILDING_GET_FINISH = 'BUILDING_GET_FINISH';
export var BUILDING_GET_FACADES_CLADDING = 'BUILDING_GET_FACADES_CLADDING';
export var BUILDING_GET_MATVIEW = 'BUILDING_GET_MATVIEW';
export var BUILDING_GET_STAGE = 'BUILDING_GET_STAGE';
export var BUILDING_SET_ACTIVE_TAB = 'BUILDING_SET_ACTIVE_TAB';
export var BUILDING_UPDATE_BUILDING = 'BUILDING_UPDATE_BUILDING';
export var BUILDING_INSERT_BUILDING = 'BUILDING_INSERT_BUILDING';
export var BUILDING_DELETE_BUILDING = 'BUILDING_DELETE_BUILDING';
export var ITEMTYPE_INPUT_GROUP_EQUIPMENT = 'ITEMTYPE_INPUT_GROUP_EQUIPMENT';
export var ITEMTYPE_FINISH = 'ITEMTYPE_FINISH';
export var ITEMTYPE_FACADES_CLADDING = 'ITEMTYPE_FACADES_CLADDING';
export var ITEMTYPE_SIMPLE = 'ITEMTYPE_SIMPLE';
