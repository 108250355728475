export var STAGE_LOADING_STAGES = 'STAGE_LOADING_STAGES';
export var STAGE_GET_STAGE = 'STAGE_GET_STAGE';
export var STAGE_RESET_FIELDS = 'STAGE_RESET_FIELDS';
export var STAGE_GET_COMPLEX = 'STAGE_GET_COMPLEX';
export var STAGE_GET_PROBLEM = 'STAGE_GET_PROBLEM';
export var STAGE_GET_DEADLINE = 'STAGE_GET_DEADLINE';
export var STAGE_GET_PERMIT = 'STAGE_GET_PERMIT';
export var STAGE_GET_PROJECT_DECLARATION = 'STAGE_GET_PROJECT_DECLARATION';
export var STAGE_GET_PROJECT_DOCUMENTATION_EXAM = 'STAGE_GET_PROJECT_DOCUMENTATION_EXAM';
export var STAGE_GET_SUBSIDIES = 'STAGE_GET_SUBSIDIES';
export var STAGE_GET_BANKS = 'STAGE_GET_BANKS';
export var STAGE_GET_SIMPLE_ITEMS = 'STAGE_GET_SIMPLE_ITEMS';
export var STAGE_ADD_PROBLEM_ACTOR = 'STAGE_ADD_PROBLEM_ACTOR';
export var STAGE_DELETE_PROBLEM_ACTOR = 'STAGE_DELETE_PROBLEM_ACTOR';
export var STAGE_CLEAR_PROBLEM_ACTOR = 'STAGE_CLEAR_PROBLEM_ACTOR';
export var STAGE_SET_ACTIVE_TAB = 'STAGE_SET_ACTIVE_TAB';
export var STAGE_UPDATE_STAGE = 'STAGE_UPDATE_STAGE';
export var STAGE_INSERT_STAGE = 'STAGE_INSERT_STAGE';
export var STAGE_DELETE_STAGE = 'STAGE_DELETE_STAGE';
export var DOCTYPE_PERMIT = 'PERMIT';
export var DOCTYPE_PROJECT_DECLARATION = 'PROJECT_DECLARATION';
export var DOCTYPE_PROJECT_DOCUMENTATION_EXAM = 'PROJECT_DOCUMENTATION_EXAM';
export var ITEMTYPE_PROBLEM = 'PROBLEM';
export var ITEMTYPE_SUBSIDIES = 'SUBSIDIES';
export var ITEMTYPE_BANK = 'BANK';
