import { SET_AD_TAGS_DATAGRID, SET_BANK_PROGRAM_DATAGRID, SET_BANK_PROGRAMS_DATAGRID, SET_BUILDINGS_DATAGRID, SET_COMPLEXES_DATAGRID, SET_DRAFT_BUILDINGS_DATAGRID, SET_DRAFT_COMPLEXES_DATAGRID, SET_PLANNINGS_DATAGRID, SET_RESALE_PLANNINGS_DATAGRID, SET_STAGES_DATAGRID, SET_APARTMENT_ADS_DATAGRID, SET_AD_TAG_GROUPS_DATAGRID } from '../constants/datagrid';
export var setComplexesDataGrid = function setComplexesDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_COMPLEXES_DATAGRID,
            payload: state
        });
    };
};
export var setStagesDataGrid = function setStagesDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_STAGES_DATAGRID,
            payload: state
        });
    };
};
export var setBuildingsDataGrid = function setBuildingsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_BUILDINGS_DATAGRID,
            payload: state
        });
    };
};
export var setApartmentAdsDataGrid = function setApartmentAdsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_APARTMENT_ADS_DATAGRID,
            payload: state
        });
    };
};
export var setDraftComplexesDataGrid = function setDraftComplexesDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_DRAFT_COMPLEXES_DATAGRID,
            payload: state
        });
    };
};
export var setDraftBuildingsDataGrid = function setDraftBuildingsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_DRAFT_BUILDINGS_DATAGRID,
            payload: state
        });
    };
};
export var setAdTagsDataGrid = function setAdTagsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_AD_TAGS_DATAGRID,
            payload: state
        });
    };
};
export var setAdTagGroupsDataGrid = function setAdTagGroupsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_AD_TAG_GROUPS_DATAGRID,
            payload: state
        });
    };
};
export var setBankProgram = function setBankProgram(state) {
    return function (dispatch) {
        dispatch({
            type: SET_BANK_PROGRAM_DATAGRID,
            payload: state
        });
    };
};
export var setBankProgramsDataGrid = function setBankProgramsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_BANK_PROGRAMS_DATAGRID,
            payload: state
        });
    };
};
export var setPlanningsDataGrid = function setPlanningsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_PLANNINGS_DATAGRID,
            payload: state
        });
    };
};
export var setResalePlanningsDataGrid = function setResalePlanningsDataGrid(state) {
    return function (dispatch) {
        dispatch({
            type: SET_RESALE_PLANNINGS_DATAGRID,
            payload: state
        });
    };
};
