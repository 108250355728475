function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require('buffer').Buffer;
export var objToBase64 = function objToBase64(obj, errorHandler) {
    try {
        if (_typeof(obj) === 'object') {
            return Buffer.from(JSON.stringify(obj)).toString('base64');
        }
        errorHandler('Ошибка при конвертации в base64');
        return null;
    }
    catch (error) {
        errorHandler('Ошибка при конвертации в base64');
        return null;
    }
};
export var strToBase64 = function strToBase64(str) {
    return Buffer.from(str).toString('base64');
};
export var objFromBase64 = function objFromBase64(base64String, errorHandler) {
    try {
        return JSON.parse(Buffer.from(base64String, 'base64').toString());
    }
    catch (error) {
        errorHandler('Ошибка при конвертации из base64');
        return null;
    }
};
