import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
    displayName: "styles__Wrapper",
    componentId: "sc-smezv3-0"
})(["min-height:100%;"]);
export var BlockToDeleteOverlay = styled.div.withConfig({
    displayName: "styles__BlockToDeleteOverlay",
    componentId: "sc-smezv3-1"
})(["visibility:hidden;position:absolute;height:calc(100% - 16px);width:calc(100% - 16px);top:0;right:0;opacity:0;transform:translate(0,32px);transition:opacity 0.5s;background:radial-gradient(ellipse at top right,#d32f2f,transparent 80%,transparent);.export-form__delete-block-button:hover + &{visibility:visible;opacity:1;}"]);
export var HeadersSelectorListHeader = styled.div.withConfig({
    displayName: "styles__HeadersSelectorListHeader",
    componentId: "sc-smezv3-2"
})(["position:absolute;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center;"]);
