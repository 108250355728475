function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
import { useEffect, useReducer, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
var itemsReducer = function itemsReducer(bindingKey) {
    return function (state, action) {
        var type = action.type, payload = action.payload;
        switch (type) {
            case 'ADD':
                {
                    return {
                        added: payload.id ? state.added : [].concat(_toConsumableArray(state.added), [payload]),
                        deleted: payload.id ? state.deleted.filter(function (item) {
                            return item[bindingKey] !== payload[bindingKey];
                        }) : state.deleted
                    };
                }
            case 'DELETE':
                {
                    return {
                        added: payload.id ? state.added : state.added.filter(function (item) {
                            return item[bindingKey] !== payload[bindingKey];
                        }),
                        deleted: payload.id ? [].concat(_toConsumableArray(state.deleted), [payload]) : state.deleted.filter(function (item) {
                            return item[bindingKey] !== payload[bindingKey];
                        })
                    };
                }
            default:
                return state;
        }
    };
};
var checkFields = function checkFields(object, keys) {
    return !(keys.length - keys.filter(function (key) {
        return !!object[key];
    }).length);
};
export var useChips = function useChips(currentValues, catalogValues, generateItem, bindingKey, currentActors) {
    var _useState = useState([]), _useState2 = _slicedToArray(_useState, 2), dataSource = _useState2[0], setDataSource = _useState2[1];
    var _useState3 = useState([]), _useState4 = _slicedToArray(_useState3, 2), values = _useState4[0], setValues = _useState4[1];
    var _useReducer = useReducer(itemsReducer(bindingKey), currentActors || {
        added: [],
        deleted: []
    }), _useReducer2 = _slicedToArray(_useReducer, 2), actors = _useReducer2[0], dispatchActors = _useReducer2[1];
    useEffect(function () {
        var curActorsToValues = (currentActors === null || currentActors === void 0 ? void 0 : currentActors.added.map(function (actor) {
            var catalogValue = catalogValues.find(function (cat) {
                return cat.id === actor[bindingKey];
            });
            if (catalogValue) {
                return {
                    id: actor[bindingKey],
                    name: catalogValue.name,
                    added: true
                };
            }
            return null;
        }).filter(function (item) {
            return !!item;
        })) || [];
        var ds = catalogValues.map(function (_ref) {
            var id = _ref.id, name = _ref.name;
            return {
                id: id,
                name: name,
                added: !uniqBy(actors !== null && actors !== void 0 && actors.added ? [].concat(_toConsumableArray(currentValues), _toConsumableArray(actors.added)) : currentValues, bindingKey).find(function (item) {
                    return item[bindingKey] === id;
                })
            };
        });
        var existingValues = currentValues.filter(function (item) {
            return checkFields(item, ['id', bindingKey]);
        }).map(function (item) {
            return ds.find(function (el) {
                return el.id === item[bindingKey];
            });
        });
        setDataSource(ds);
        setValues(uniqBy([].concat(_toConsumableArray(existingValues), _toConsumableArray(curActorsToValues)), 'id'));
    }, []);
    var buildSelectedItem = function buildSelectedItem(item) {
        return item.added ? generateItem(item) : _objectSpread({}, currentValues.find(function (el) {
            return el[bindingKey] === item.id;
        }));
    };
    var onItemAdd = function onItemAdd(item) {
        var selectedItem = buildSelectedItem(item);
        dispatchActors({
            type: 'ADD',
            payload: selectedItem
        });
        setValues(function (oldValues) {
            return [].concat(_toConsumableArray(oldValues), [item]);
        });
    };
    var onItemDelete = function onItemDelete(item) {
        var selectedItem = buildSelectedItem(item);
        dispatchActors({
            type: 'DELETE',
            payload: selectedItem
        });
        setValues(function (oldValues) {
            return oldValues.filter(function (el) {
                return el.id !== item.id;
            });
        });
    };
    return {
        onItemAdd: onItemAdd,
        onItemDelete: onItemDelete,
        values: values,
        dataSource: dataSource,
        actors: actors
    };
};
