import { RESALE_PLANNING_SET_NEXT_ID, RESALE_PLANNING_SET_PLANNING, RESALE_PLANNING_SET_PREV_ID } from './_action.types';
export var setResalePlanning = function setResalePlanning(data) {
    return function (dispatch) {
        dispatch({
            type: RESALE_PLANNING_SET_PLANNING,
            payload: data
        });
    };
};
export var setResalePlanningPrevId = function setResalePlanningPrevId(payload) {
    return {
        type: RESALE_PLANNING_SET_PREV_ID,
        payload: payload
    };
};
export var setResalePlanningNextId = function setResalePlanningNextId(payload) {
    return {
        type: RESALE_PLANNING_SET_NEXT_ID,
        payload: payload
    };
};
