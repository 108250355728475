import defaultDeveloperNoInformation from './defaultDeveloperNoInformation.json';
export var defaultNewComplexData = {
    id: undefined,
    name: null,
    slug: null,
    complexStatusId: null,
    isComplex: true,
    catalogDeveloperId: null,
    // Not null
    catalogHousingClassId: null,
    // Not null
    massMarket: false,
    isActive: false,
    isInactive: false,
    houseReady: null,
    isLowRise: null,
    isProblem: false,
    nmarketAlias: null,
    nmarketId: null,
    cianId: null,
    cianAlias: null,
    avitoId: null,
    avitoAlias: null,
    avitoDescription: null,
    isIncludeFeedAvitoNoActive: false,
    deleted: false,
    cityId: null,
    addressText: null,
    areaId: null,
    municipalDistrictId: null,
    latitude: null,
    longitude: null,
    parkDistance: null,
    parkDistanceMsk: null,
    panorama: null,
    mainPhoto: null,
    commerceSquare: null,
    isCycleParking: null,
    groundsForWalkingDogs: null,
    isClosedYardCars: null,
    catalogProtectionId: null,
    videoSurveillanceSystem: null,
    isTerritoryEnclosure: null,
    specialImprovementObjects: null,
    placesForAdults: null,
    autoAvrTimeToDistrict: null,
    otAvrTimeToDistrict: null,
    metaTitle: null,
    metaKeywords: null,
    metaDescription: null,
    userId: 1,
    developerNotInfo: JSON.stringify(defaultDeveloperNoInformation)
};
export var COMPLEX_LOADING_COMPLEXES = 'COMPLEX_LOADING_COMPLEXES';
export var COMPLEX_GET_NEW_COMPLEX_DATA = 'COMPLEX_GET_NEW_COMPLEX_DATA';
export var COMPLEX_GET_COMPLEX = 'COMPLEX_GET_COMPLEX';
export var COMPLEX_UPDATE_COMPLEX = 'COMPLEX_UPDATE_COMPLEX';
export var COMPLEX_NEED_UPDATE_DATA = 'COMPLEX_NEED_UPDATE_DATA';
export var COMPLEX_UPDATE_COMPLEX_DATA = 'COMPLEX_UPDATE_COMPLEX_DATA';
export var COMPLEX_UPDATE_PROCESS = 'COMPLEX_UPDATE_PROCESS';
export var COMPLEX_INSERT_COMPLEX = 'COMPLEX_INSERT_COMPLEX';
export var COMPLEX_DELETE_COMPLEX = 'COMPLEX_DELETE_COMPLEX';
export var COMPLEX_LOADING_ALIASES = 'COMPLEX_LOADING_ALIASES';
export var COMPLEX_GET_ALIAS = 'COMPLEX_GET_ALIAS';
export var COMPLEX_UPDATE_ALIAS = 'COMPLEX_UPDATE_ALIAS';
export var COMPLEX_INSERT_ALIAS = 'COMPLEX_INSERT_ALIAS';
export var COMPLEX_DELETE_ALIAS = 'COMPLEX_DELETE_ALIAS';
export var COMPLEX_ADD_ALIASES_ACTOR = 'COMPLEX_ADD_ALIASES_ACTOR';
export var COMPLEX_DELETE_ALIASES_ACTOR = 'COMPLEX_DELETE_ALIASES_ACTOR';
export var COMPLEX_EXCHANGE_ALIASES_ACTOR = 'COMPLEX_EXCHANGE_ALIASES_ACTOR';
export var COMPLEX_LOADING_PARKING_TYPES = 'COMPLEX_LOADING_PARKING_TYPES';
export var COMPLEX_GET_PARKING_TYPE = 'COMPLEX_GET_PARKING_TYPE';
export var COMPLEX_UPDATE_PARKING_TYPE = 'COMPLEX_UPDATE_PARKING_TYPE';
export var COMPLEX_INSERT_PARKING_TYPE = 'COMPLEX_INSERT_PARKING_TYPE';
export var COMPLEX_DELETE_PARKING_TYPE = 'COMPLEX_DELETE_PARKING_TYPE';
export var COMPLEX_LOADING_SIMPLE_COMPLEX = 'COMPLEX_LOADING_SIMPLE_COMPLEX';
export var COMPLEX_GET_SIMPLE = 'COMPLEX_GET_SIMPLE';
export var COMPLEX_UPDATE_SIMPLE = 'COMPLEX_UPDATE_SIMPLE';
export var COMPLEX_INSERT_SIMPLE = 'COMPLEX_INSERT_SIMPLE';
export var COMPLEX_DELETE_SIMPLE = 'COMPLEX_DELETE_SIMPLE';
export var COMPLEX_LOADING_PROBLEMS = 'COMPLEX_LOADING_PROBLEMS';
export var COMPLEX_GET_PROBLEM = 'COMPLEX_GET_PROBLEM';
export var COMPLEX_UPDATE_PROBLEM = 'COMPLEX_UPDATE_PROBLEM';
export var COMPLEX_INSERT_PROBLEM = 'COMPLEX_INSERT_PROBLEM';
export var COMPLEX_DELETE_PROBLEM = 'COMPLEX_DELETE_PROBLEM';
export var COMPLEX_LOADING_METRO = 'COMPLEX_LOADING_METRO';
export var COMPLEX_GET_METRO = 'COMPLEX_GET_METRO';
export var COMPLEX_UPDATE_METRO = 'COMPLEX_UPDATE_METRO';
export var COMPLEX_INSERT_METRO = 'COMPLEX_INSERT_METRO';
export var COMPLEX_DELETE_METRO = 'COMPLEX_DELETE_METRO';
export var COMPLEX_LOADING_PHOTOS = 'COMPLEX_LOADING_PHOTOS';
export var COMPLEX_INSERT_PHOTO = 'COMPLEX_INSERT_PHOTO';
export var COMPLEX_DELETE_PHOTO = 'COMPLEX_DELETE_PHOTO';
export var COMPLEX_LOADING_INFRASTRUCTURE_AUTO = 'COMPLEX_LOADING_INFRASTRUCTURE_AUTO';
export var COMPLEX_LOADING_PLAYGROUNDS = 'COMPLEX_LOADING_PLAYGROUNDS';
export var COMPLEX_GET_PLAYGROUNDS = 'COMPLEX_GET_PLAYGROUNDS';
export var COMPLEX_UPDATE_PLAYGROUNDS = 'COMPLEX_UPDATE_PLAYGROUNDS';
export var COMPLEX_INSERT_PLAYGROUNDS = 'COMPLEX_INSERT_PLAYGROUNDS';
export var COMPLEX_DELETE_PLAYGROUNDS = 'COMPLEX_DELETE_PLAYGROUNDS';
export var COMPLEX_LOADING_SAFETY_PLAYGROUNDS = 'COMPLEX_LOADING_SAFETY_PLAYGROUNDS';
export var COMPLEX_GET_SAFETY_PLAYGROUNDS = 'COMPLEX_GET_SAFETY_PLAYGROUNDS';
export var COMPLEX_UPDATE_SAFETY_PLAYGROUNDS = 'COMPLEX_UPDATE_SAFETY_PLAYGROUNDS';
export var COMPLEX_INSERT_SAFETY_PLAYGROUNDS = 'COMPLEX_INSERT_SAFETY_PLAYGROUNDS';
export var COMPLEX_DELETE_SAFETY_PLAYGROUNDS = 'COMPLEX_DELETE_SAFETY_PLAYGROUNDS';
export var COMPLEX_EXCHANGE_PARKING_TYPES_ACTOR = 'COMPLEX_EXCHANGE_PARKING_TYPES_ACTOR';
export var COMPLEX_SET_SIMPLE_COMPLEX_ACTORS = 'COMPLEX_SET_SIMPLE_COMPLEX_ACTORS';
export var COMPLEX_SET_COMPLEX_PROBLEM_ACTORS = 'COMPLEX_SET_COMPLEX_PROBLEM_ACTORS';
export var COMPLEX_ADD_METRO_ACTOR = 'COMPLEX_ADD_METRO_ACTOR';
export var COMPLEX_DELETE_METRO_ACTOR = 'COMPLEX_DELETE_METRO_ACTOR';
export var COMPLEX_SET_MAIN_PHOTO = 'COMPLEX_SET_MAIN_PHOTO';
export var COMPLEX_SAVE_MAIM_PHOTO = 'COMPLEX_SAVE_MAIM_PHOTO';
export var COMPLEX_ADD_PHOTO_ACTOR = 'COMPLEX_ADD_PHOTO_ACTOR';
export var COMPLEX_DELETE_PHOTO_ACTOR = 'COMPLEX_DELETE_PHOTO_ACTOR';
export var COMPLEX_ADD_PLAYGROUNDS_ACTOR = 'COMPLEX_ADD_PLAYGROUNDS_ACTOR';
export var COMPLEX_DELETE_PLAYGROUNDS_ACTOR = 'COMPLEX_DELETE_PLAYGROUNDS_ACTOR';
export var COMPLEX_ADD_SAFETY_PLAYGROUNDS_ACTOR = 'COMPLEX_ADD_SAFETY_PLAYGROUNDS_ACTOR';
export var COMPLEX_DELETE_SAFETY_PLAYGROUNDS_ACTOR = 'COMPLEX_DELETE_SAFETY_PLAYGROUNDS_ACTOR';
export var COMPLEX_ADD_PARKING_TYPE_ACTOR = 'COMPLEX_ADD_PARKING_TYPE_ACTOR';
export var COMPLEX_DELETE_PARKING_TYPE_ACTOR = 'COMPLEX_DELETE_PARKING_TYPE_ACTOR';
export var COMPLEX_SET_COMPLEX = 'COMPLEX_SET_COMPLEX';
export var PARKING_ACCESS_ID = 5;
export var GREENWOOD_ID = 3;
