import styled from 'styled-components';
export var Root = styled.div.withConfig({
    displayName: "styles__Root",
    componentId: "sc-nm86zv-0"
})(["display:flex;align-items:center;justify-content:space-between;grid-area:header;padding:0 20px;background-color:#1976d23d;border-bottom:1px solid rgba(0,0,0,0.12);box-shadow:rgb(0 0 0 / 5%) 0 3px 5px 0;"]);
export var MicroText = styled.span.withConfig({
    displayName: "styles__MicroText",
    componentId: "sc-nm86zv-1"
})(["padding:0 4px;font-size:9px;text-decoration:none;"]);
export var SideRoot = styled.div.withConfig({
    displayName: "styles__SideRoot",
    componentId: "sc-nm86zv-2"
})(["display:flex;align-items:center;justify-content:center;"]);
