function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
var _objectHasOwn = function (object, property) {
    if (typeof object === 'undefined' || object === null) {
        throw new TypeError('Cannot convert undefined or null to object');
    }
    return Object.prototype.hasOwnProperty.call(Object(object), property);
};
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'cookie';
import { useSnackbar } from 'notistack';
import { selectUserToken } from '@/entities/session';
import { useDispatch, useSelector, useStore } from '@/hooks';
import { getUserByToken } from '../actions';
import { COMPLEX_SET_COMPLEX, LOGIN_SET_NEED_LOGIN, USER_RESET_USER } from '../constants';
import { currentUserCan, currentUserCanbyModule, isEqualObjects, setCookie } from './utils';
export var useCookie = function useCookie(name) {
    var documentCookie = cookie.parse(document.cookie);
    var _useState = useState({}), _useState2 = _slicedToArray(_useState, 2), cookies = _useState2[0], setCookies = _useState2[1];
    var getCookies = function getCookies(names) {
        if (!names)
            return documentCookie;
        if (Array.isArray(names)) {
            return names.reduce(function (acc, curr) {
                return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, curr, documentCookie[curr]));
            }, {});
        }
        return _defineProperty({}, names, documentCookie[names]);
    };
    var removeCookie = function removeCookie(cookieName) {
        document.cookie = "".concat(cookieName, "=;max-age=-1");
        setCookies(getCookies(name));
    };
    var newCookies = getCookies(name);
    if (isEqualObjects(cookies, newCookies)) {
        return {
            cookies: cookies,
            getCookies: getCookies,
            setCookie: setCookie,
            removeCookie: removeCookie
        };
    }
    setCookies(newCookies);
    return {
        cookies: newCookies,
        getCookies: getCookies,
        setCookie: setCookie,
        removeCookie: removeCookie
    };
};
export var useAuth = function useAuth() {
    var dispatch = useDispatch();
    var store = useStore();
    var _useSnackbar = useSnackbar(), enqueueSnackbar = _useSnackbar.enqueueSnackbar;
    var location = useLocation();
    var navigate = useNavigate();
    var _useState3 = useState(false), _useState4 = _slicedToArray(_useState3, 2), isAuthInit = _useState4[0], setIsAuthInit = _useState4[1];
    var _useCookie = useCookie('living_bo_token'), cookies = _useCookie.cookies, removeCookie = _useCookie.removeCookie;
    var token = useSelector(selectUserToken);
    var expires = useSelector(function (state) {
        return state.user.expires;
    });
    var needLogin = useSelector(function (state) {
        return state.login.needLogin;
    });
    var boToken = /^[a-zA-Z0-9]+$/g.test(cookies === null || cookies === void 0 ? void 0 : cookies.living_bo_token) ? cookies.living_bo_token : undefined;
    useEffect(function () {
        if (expires && token && expires < new Date().valueOf()) {
            dispatch({
                type: USER_RESET_USER
            });
            dispatch({
                type: LOGIN_SET_NEED_LOGIN,
                payload: true
            });
            removeCookie('living_bo_token');
            setIsAuthInit(true);
        }
    }, [expires, token]);
    useEffect(function () {
        if (boToken) {
            getUserByToken(boToken)(dispatch, store.getState, enqueueSnackbar).then(function (data) {
                if (data) {
                    dispatch({
                        type: LOGIN_SET_NEED_LOGIN,
                        payload: false
                    });
                }
                else {
                    removeCookie('living_bo_token');
                }
                setIsAuthInit(true);
            });
        }
        else {
            dispatch({
                type: USER_RESET_USER
            });
            dispatch({
                type: LOGIN_SET_NEED_LOGIN,
                payload: true
            });
            setIsAuthInit(true);
        }
    }, [boToken]);
    useEffect(function () {
        if (isAuthInit && needLogin && location.pathname !== '/login') {
            navigate('/login', {
                state: {
                    backPath: location.pathname
                }
            });
            setIsAuthInit(true);
        }
        else if (isAuthInit && !needLogin && location.pathname === '/login') {
            var _location$state;
            navigate(((_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state.backPath) || '/');
            setIsAuthInit(true);
        }
    }, [isAuthInit, needLogin, token, location]);
    return isAuthInit;
};
export var useComplexChangeHandler = function useComplexChangeHandler() {
    var dispatch = useDispatch();
    // eslint-disable-next-line no-undef
    return function (field, checked) {
        return function (event) {
            var componentValue = checked ? event.target.checked : event.target.value;
            dispatch({
                type: COMPLEX_SET_COMPLEX,
                payload: _defineProperty({}, field, +componentValue !== -1 ? componentValue : null)
            });
        };
    };
};
export var useSmartSelector = function useSmartSelector(staticState, changedState) {
    var staticValue = useSelector(staticState);
    var changedValue = useSelector(changedState);
    var _useState5 = useState(null), _useState6 = _slicedToArray(_useState5, 2), result = _useState6[0], setResult = _useState6[1];
    var newResult = staticValue ? Object.keys(staticValue).reduce(function (acc, key) {
        return _objectHasOwn(changedValue, key) ? _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, changedValue[key])) : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, staticValue[key]));
    }, {}) : null;
    if (isEqualObjects(result, newResult)) {
        return result;
    }
    setResult(newResult);
    return newResult;
};
export var useComplexNeedSaving = function useComplexNeedSaving() {
    var changedComplex = useSelector(function (state) {
        return state.complex.changedComplex;
    });
    var aliasesActors = useSelector(function (state) {
        return state.complex.aliasesActors;
    });
    var parkingTypesActors = useSelector(function (state) {
        return state.complex.parkingTypesActors;
    });
    var parkingAccessActors = useSelector(function (state) {
        return state.complex.simpleComplexActors;
    });
    var complexProblemsActors = useSelector(function (state) {
        return state.complex.complexProblemsActors;
    });
    var complexMetroActors = useSelector(function (state) {
        return state.complex.complexMetroActors;
    });
    var complexMainPhoto = useSelector(function (state) {
        return state.complex.complexMainPhoto;
    });
    var complexPhotosActors = useSelector(function (state) {
        return state.complex.complexPhotosActors;
    });
    var complexPlaygroundActors = useSelector(function (state) {
        return state.complex.complexPlaygroundActors;
    });
    var complexSafetyPlaygroundsActors = useSelector(function (state) {
        return state.complex.complexSafetyPlaygroundsActors;
    });
    var _useState7 = useState(false), _useState8 = _slicedToArray(_useState7, 2), needSaving = _useState8[0], setNeedSaving = _useState8[1];
    useEffect(function () {
        var _Object$keys;
        setNeedSaving(!!((_Object$keys = Object.keys(changedComplex)) !== null && _Object$keys !== void 0 && _Object$keys.length || aliasesActors.added.length || aliasesActors.deleted.length || parkingTypesActors.added.length || parkingTypesActors.deleted.length || parkingAccessActors.added.length || parkingAccessActors.deleted.length || complexProblemsActors.added.length || complexProblemsActors.deleted.length || complexMetroActors.added.length || complexMetroActors.deleted.length || complexPhotosActors.added.length || complexPhotosActors.deleted.length || complexPlaygroundActors.added.length || complexPlaygroundActors.deleted.length || complexSafetyPlaygroundsActors.added.length || complexSafetyPlaygroundsActors.deleted.length || !!complexMainPhoto));
    }, [changedComplex, aliasesActors, parkingTypesActors, parkingAccessActors, complexProblemsActors, complexMetroActors, complexPhotosActors, complexPlaygroundActors, complexSafetyPlaygroundsActors, complexMainPhoto]);
    return needSaving;
};
export var useCustomForm = function useCustomForm(defaultValues) {
    var _useState9 = useState({}), _useState10 = _slicedToArray(_useState9, 2), data = _useState10[0], setData = _useState10[1];
    var _useState11 = useState(defaultValues), _useState12 = _slicedToArray(_useState11, 2), formData = _useState12[0], setFormData = _useState12[1];
    var onChange = function onChange(event) {
        var _event$target = event.target, name = _event$target.name, value = _event$target.value, type = _event$target.type;
        switch (type) {
            case 'number':
                setFormData(function (oldFormData) {
                    return _objectSpread(_objectSpread({}, oldFormData), {}, _defineProperty({}, name, value.replaceAll(',', '.')));
                });
                break;
            case 'checkbox':
                setFormData(function (oldFormData) {
                    return _objectSpread(_objectSpread({}, oldFormData), {}, _defineProperty({}, name, !oldFormData[name]));
                });
                break;
            default:
                setFormData(function (oldFormData) {
                    return _objectSpread(_objectSpread({}, oldFormData), {}, _defineProperty({}, name, value));
                });
                break;
        }
    };
    // Чтобы избежать лишних данных в запросе
    useEffect(function () {
        Object.keys(formData || {}).forEach(function (key) {
            var formValue = formData[key];
            if (formValue !== (defaultValues || {})[key]) {
                setData(function (oldValues) {
                    return _objectSpread(_objectSpread({}, oldValues), {}, _defineProperty({}, key, formValue));
                });
            }
        });
    }, [formData]);
    // Эффект для очистки от пустых ключей
    useEffect(function () {
        Object.keys(data).forEach(function (key) {
            if (data[key] === '') {
                data[key] = null;
            }
        });
    }, [data]);
    return {
        formData: formData,
        onChange: onChange,
        data: data
    };
};
export var useAccessByRoles = function useAccessByRoles(roles) {
    var user = useSelector(function (state) {
        return state.user;
    });
    var _useState13 = useState('loading'), _useState14 = _slicedToArray(_useState13, 2), isValidAccess = _useState14[0], setIsValidAccess = _useState14[1];
    useEffect(function () {
        if (user !== null && user !== void 0 && user.token && user !== null && user !== void 0 && user.loaded) {
            setIsValidAccess(currentUserCan(roles, user));
        }
    }, [user]);
    return isValidAccess;
};
export var useAccessByModule = function useAccessByModule(module) {
    var user = useSelector(function (state) {
        return state.user;
    });
    var _useState15 = useState('loading'), _useState16 = _slicedToArray(_useState15, 2), isValidAccess = _useState16[0], setIsValidAccess = _useState16[1];
    useEffect(function () {
        if (user !== null && user !== void 0 && user.token && user !== null && user !== void 0 && user.loaded) {
            setIsValidAccess(currentUserCanbyModule(module, user));
        }
    }, [user]);
    return isValidAccess;
};
