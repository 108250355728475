function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { omit as _omit } from 'lodash';
import { lastIndex } from '@/utils/array';
// from bll datagrid to ui dxDataGrid
export var mapFromStateToDataGridState = function mapFromStateToDataGridState(state, gridOptions) {
    return _objectSpread(_objectSpread({}, _omit(gridOptions, ['columnsMap'])), {}, {
        columns: state.columns.map(function (column) {
            return _objectSpread(_objectSpread(_objectSpread({}, column), gridOptions.columnsMap[column.name]), {}, {
                filterValue: /^\d{4}\/\d{2}\/\d{2}/.test("".concat(column.filterValue)) ? new Date(column.filterValue) : column.filterValue
            });
        }),
        searchText: state.searchText,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize
    });
};
// from ui dxDataGrid to bll datagrid
export var mapFromDataGridStateToState = function mapFromDataGridStateToState(state, gridOptions) {
    return {
        columns: state.columns.map(function (_ref) {
            var name = _ref.name, visible = _ref.visible, visibleIndex = _ref.visibleIndex, filterValue = _ref.filterValue, filterValues = _ref.filterValues, selectedFilterOperation = _ref.selectedFilterOperation, width = _ref.width, sortOrder = _ref.sortOrder;
            return {
                name: name,
                visible: visible,
                visibleIndex: visibleIndex,
                filterValue: filterValue instanceof Date ? "".concat(filterValue.toLocaleDateString('ru').split('.').reverse().join('/'), " 00:00:00") : filterValue,
                filterValues: filterValues,
                selectedFilterOperation: selectedFilterOperation,
                width: width,
                sortOrder: sortOrder
            };
        }),
        searchText: state.searchText || gridOptions.searchText,
        pageIndex: state.pageIndex || gridOptions.pageIndex,
        pageSize: state.pageSize || gridOptions.pageSize
    };
};
// extract filter & sort options from bll datagrid columns
export var getFilterSortOptions = function getFilterSortOptions(columns) {
    var getFilterOperator = function getFilterOperator(initialOperator, filterIndex) {
        if (!initialOperator) {
            return '=';
        }
        if (initialOperator === 'between' && filterIndex === 0) {
            return '>=';
        }
        if (initialOperator === 'between' && filterIndex === 1) {
            return '<=';
        }
        return initialOperator;
    };
    var filterSortOptions = {
        filter: columns.map(function (column) {
            if (!column.filterValue && !column.filterValues) {
                return false;
            }
            if (Array.isArray(column.filterValue)) {
                return column.filterValue.map(function (filterValue, i) {
                    return [column.name, getFilterOperator(column.selectedFilterOperation, i), filterValue];
                }).reduce(function (acc, curr, i, arr) {
                    return i === lastIndex(arr) ? [].concat(_toConsumableArray(acc), [curr]) : [].concat(_toConsumableArray(acc), [curr, 'and']);
                }, []).reduce(function (_acc, curr, i, arr) {
                    return arr.length === 1 ? curr : arr;
                }, []);
            }
            if (Array.isArray(column.filterValues)) {
                return column.filterValues.map(function (filterValue, i) {
                    return [column.name, getFilterOperator(column.selectedFilterOperation, i), filterValue[2]];
                }).reduce(function (acc, curr, i, arr) {
                    return i === lastIndex(arr) ? [].concat(_toConsumableArray(acc), [curr]) : [].concat(_toConsumableArray(acc), [curr, 'and']);
                }, []).reduce(function (_acc, curr, i, arr) {
                    return arr.length === 1 ? curr : arr;
                }, []);
            }
            return [column.name, getFilterOperator(column.selectedFilterOperation), column.filterValue];
        }).filter(Boolean).reduce(function (acc, curr, i, arr) {
            return i === lastIndex(arr) ? [].concat(_toConsumableArray(acc), [curr]) : [].concat(_toConsumableArray(acc), [curr, 'and']);
        }, []).reduce(function (_acc, curr, _i, arr) {
            return arr.length === 1 ? curr : arr;
        }, []),
        sort: columns.filter(function (column) {
            return column.sortOrder;
        }).map(function (column) {
            return {
                selector: column.name,
                desc: column.sortOrder === 'desc'
            };
        })
    };
    return filterSortOptions;
};
