export var createRadialGradient = function createRadialGradient(context, color1, color2) {
    var chartArea = context.chart.chartArea;
    var centerX = (chartArea.left + chartArea.right) / 3;
    var centerY = (chartArea.top + chartArea.bottom) / 1.5;
    var r = Math.min((chartArea.right - chartArea.left) / 2, (chartArea.bottom - chartArea.top) / 2);
    var ctx = context.chart.ctx;
    var gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
};
