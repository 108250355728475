import { Paper } from '@mui/material';
import styled from 'styled-components';
import { COLOR_PALETTE } from '@/consts';
import { GridItem } from '@/view/layout';
export var AdApartmentHeader = styled.header.withConfig({
    displayName: "styles__AdApartmentHeader",
    componentId: "sc-uwmmth-0"
})(["display:grid;grid-template-columns:80% 20%;width:100%;"]);
export var ContentWrapper = styled.div.withConfig({
    displayName: "styles__ContentWrapper",
    componentId: "sc-uwmmth-1"
})(["display:flex;flex-grow:1;min-height:100%;padding:15px 0;"]);
export var DiptychWing = styled(GridItem).attrs({
    xs: 6
}).withConfig({
    displayName: "styles__DiptychWing",
    componentId: "sc-uwmmth-2"
})(["position:relative;padding:", ";border-top:", ";", ";", ";&::before{content:'';position:absolute;top:-16px;left:0;width:100%;height:18px;background:linear-gradient(to ", ",#ffffff00,#ffffffaa 90%,#ffffff);}"], function (_ref) {
    var $isLeft = _ref.$isLeft;
    return $isLeft ? '8px 8px 0 0' : '8px 0 0 8px';
}, function (_ref2) {
    var $borderColor = _ref2.$borderColor;
    return "16px solid ".concat($borderColor);
}, function (_ref3) {
    var $isLeft = _ref3.$isLeft, $borderColor = _ref3.$borderColor;
    return $isLeft ? "border-right: 8px solid ".concat($borderColor) : "border-left: 8px solid ".concat($borderColor);
}, function (_ref4) {
    var $isLeft = _ref4.$isLeft;
    return $isLeft ? 'border-top-right-radius: 48px' : 'border-top-left-radius: 48px';
}, function (_ref5) {
    var $isLeft = _ref5.$isLeft;
    return $isLeft ? 'left' : 'right';
});
export var ComparisonChipBase = styled(Paper).withConfig({
    displayName: "styles__ComparisonChipBase",
    componentId: "sc-uwmmth-3"
})(["position:absolute;top:50%;left:100%;padding-left:8px;padding-right:8px;min-width:max-content;border:", ";color:", ";transform:translate(calc(-50% + 8px),-50%);&::before{content:", ";position:absolute;width:24px;height:24px;top:50%;left:50%;transform:translate(-35%,-50%) rotateY(45deg) rotateZ(45deg);background-color:#ffffff;z-index:-1;}&::after{content:", ";position:absolute;width:24px;height:24px;top:50%;left:50%;transform:translate(-65%,-50%) rotateY(45deg) rotateZ(45deg);background-color:#ffffff;z-index:-1;}"], function (_ref6) {
    var $isSimilar = _ref6.$isSimilar, $isNoData = _ref6.$isNoData;
    return $isSimilar ? "2px solid ".concat($isNoData ? COLOR_PALETTE.WARNING : COLOR_PALETTE.SUCCESS) : undefined;
}, function (_ref7) {
    var $isSimilar = _ref7.$isSimilar;
    return $isSimilar ? undefined : COLOR_PALETTE.ERROR;
}, function (_ref8) {
    var $isSimilar = _ref8.$isSimilar;
    return $isSimilar ? '' : '""';
}, function (_ref9) {
    var $isSimilar = _ref9.$isSimilar;
    return $isSimilar ? '' : '""';
});
