import { queryOptions } from '@tanstack/react-query';
import { http } from '@/api';
import { EP, ep } from '@/api/endpoints';
var cacheKey = 'stats';
export var getStatsQueryOptions = function getStatsQueryOptions(timestamp, token) {
    return queryOptions({
        queryKey: [cacheKey, timestamp],
        queryFn: function queryFn() {
            return http.get({
                url: ep(EP.HOME_PAGE_STATS, {}, {
                    refreshTimestamp: timestamp
                }),
                token: token
            }).then(function (_ref) {
                var data = _ref.data, meta = _ref.meta;
                return {
                    data: data,
                    timestamp: meta.timestamp
                };
            });
        }
    });
};
