function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { FAIL, LOOKUP_LOADING_ROLES, START, STOP, SUCCESS } from '../../constants';
import { LOOKUP_LOADING, LOOKUP_INSERT, LOOKUP_UPDATE } from '../../constants/collections';
var initialState = {
    roles: {
        roles: null,
        count: 0,
        loading: false,
        loaded: false
    },
    complexStatus: null,
    catalogDeveloper: null,
    catalogHousingClass: null,
    catalogParkingType: null,
    catalogProblem: null,
    municipalDistrict: null,
    metro: null,
    catalogPlaygroundsAndSportsFields: null,
    catalogSafetyPlaygroundsAndSportsFields: null,
    catalogProtection: null,
    catalogBuildingStatus: null,
    catalogContract: null,
    catalogSubsidies: null,
    catalogBank: null,
    loading: false,
    area: null,
    catalogBalconyGlazing: null,
    catalogFacadesCladding: null,
    catalogFinish: null,
    catalogGlazedWindows: null,
    catalogInputGroupEquipment: null,
    catalogSimpleItem: null,
    catalogSimpleToItem: null,
    catalogStrollers: null,
    catalogTechnology: null
};
var lookup = function lookup() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case LOOKUP_LOADING_ROLES + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                roles: _objectSpread(_objectSpread({}, state.roles), {}, {
                    loading: true
                })
            });
        case LOOKUP_LOADING_ROLES + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                roles: _objectSpread(_objectSpread({}, action.payload), {}, {
                    loading: false,
                    loaded: true
                })
            });
        case LOOKUP_LOADING_ROLES + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                roles: _objectSpread(_objectSpread({}, state.roles), {}, {
                    loading: false,
                    loaded: false
                })
            });
        case LOOKUP_LOADING + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true
            });
        case LOOKUP_LOADING + SUCCESS:
            return _objectSpread(_objectSpread({}, state), action.payload);
        case LOOKUP_LOADING + FAIL:
            return state;
        case LOOKUP_LOADING + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false
            });
        case LOOKUP_INSERT + START:
            return state;
        case LOOKUP_INSERT + SUCCESS:
            return _objectSpread(_objectSpread({}, state), action.payload);
        case LOOKUP_INSERT + FAIL:
            return state;
        case LOOKUP_INSERT + STOP:
            return state;
        case LOOKUP_UPDATE + START:
            return state;
        case LOOKUP_UPDATE + SUCCESS:
            return _objectSpread(_objectSpread({}, state), action.payload);
        case LOOKUP_UPDATE + FAIL:
            return state;
        case LOOKUP_UPDATE + STOP:
            return state;
        default:
            return state;
    }
};
export default lookup;
