function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
import DataSource from 'devextreme/data/data_source';
import { useSelector } from '@/hooks';
import { PARKING_ACCESS_ID, GREENWOOD_ID, SIMPLE_ITEMS_EN_EFF, SIMPLE_ITEMS_WINDOWS, SIMPLE_ITEMS_MGN_ACCESSIBILITY, SIMPLE_ITEMS_BALCONY_APPEARANCE } from '../../constants';
import getArrayOfSImpleItemsByCatalog from '../../utilities/getArrayOfSImpleItemsByCatalog';
import { snakeCaseToCamelCase } from '../../utilities/utils';
import { buildingDeveloperNotInfoStore, complexDeveloperNotInfoStore, stageDeveloperNotInfoStore } from './constants';
var onFilterKeyChange = function onFilterKeyChange(onChange, i, filtersArray) {
    return function (newKey) {
        if (newKey && newKey.includes('.')) {
            onChange([[newKey, '=', null]]);
        }
        else {
            filtersArray[i] = newKey ? [newKey, '=', null] : [null];
            onChange(_toConsumableArray(filtersArray));
        }
    };
};
var onFilterOperatorChange = function onFilterOperatorChange(onChange, i, filtersArray) {
    return function (operator) {
        var _filtersArray$i, _filtersArray$i2;
        filtersArray[i] = [((_filtersArray$i = filtersArray[i]) === null || _filtersArray$i === void 0 ? void 0 : _filtersArray$i[0]) || null, operator, ((_filtersArray$i2 = filtersArray[i]) === null || _filtersArray$i2 === void 0 ? void 0 : _filtersArray$i2[2]) || null];
        onChange(_toConsumableArray(filtersArray));
    };
};
var onFilterValueChange = function onFilterValueChange(onChange, i, filtersArray) {
    return function (newValue) {
        var _filtersArray$i3, _filtersArray$i4;
        filtersArray[i] = [((_filtersArray$i3 = filtersArray[i]) === null || _filtersArray$i3 === void 0 ? void 0 : _filtersArray$i3[0]) || null, ((_filtersArray$i4 = filtersArray[i]) === null || _filtersArray$i4 === void 0 ? void 0 : _filtersArray$i4[1]) || null, newValue];
        onChange(_toConsumableArray(filtersArray));
    };
};
var onFilterComparatorOperatorChange = function onFilterComparatorOperatorChange(onChange, i, filtersArray) {
    return function (newValue) {
        var _filtersArray$i5, _filtersArray$i6, _filtersArray$i7;
        filtersArray[i] = [((_filtersArray$i5 = filtersArray[i]) === null || _filtersArray$i5 === void 0 ? void 0 : _filtersArray$i5[0]) || null, ((_filtersArray$i6 = filtersArray[i]) === null || _filtersArray$i6 === void 0 ? void 0 : _filtersArray$i6[1]) || null, ((_filtersArray$i7 = filtersArray[i]) === null || _filtersArray$i7 === void 0 ? void 0 : _filtersArray$i7[2]) || null, newValue];
        onChange(_toConsumableArray(filtersArray));
    };
};
var onFilterComparatorKeyChange = function onFilterComparatorKeyChange(onChange, i, filtersArray) {
    return function (newValue) {
        var _filtersArray$i8, _filtersArray$i9, _filtersArray$i10, _filtersArray$i11;
        filtersArray[i] = [((_filtersArray$i8 = filtersArray[i]) === null || _filtersArray$i8 === void 0 ? void 0 : _filtersArray$i8[0]) || null, ((_filtersArray$i9 = filtersArray[i]) === null || _filtersArray$i9 === void 0 ? void 0 : _filtersArray$i9[1]) || null, ((_filtersArray$i10 = filtersArray[i]) === null || _filtersArray$i10 === void 0 ? void 0 : _filtersArray$i10[2]) || null, ((_filtersArray$i11 = filtersArray[i]) === null || _filtersArray$i11 === void 0 ? void 0 : _filtersArray$i11[3]) || null, newValue];
        onChange(_toConsumableArray(filtersArray));
    };
};
export var getGroupedFieldsDataSource = function getGroupedFieldsDataSource(schema, groupEnum, skipFields) {
    return new DataSource({
        store: schema === null || schema === void 0 ? void 0 : schema.fields.filter(function (field) {
            return !(skipFields !== null && skipFields !== void 0 && skipFields.find(function (toSkip) {
                return field.key === toSkip;
            }));
        }).map(function (field) {
            return field.group ? _objectSpread(_objectSpread({}, field), {}, {
                group: groupEnum[field.group],
                groupIndex: field.group
            }) : _objectSpread(_objectSpread({}, field), {}, {
                group: 'Другое',
                groupIndex: 100
            });
        }),
        sort: {
            selector: 'groupIndex',
            desc: false
        },
        key: 'key',
        group: 'groupIndex'
    });
};
var onSpecialFilterAggregationTypeChange = function onSpecialFilterAggregationTypeChange(onChange) {
    return function (type) {
        if (type) {
            onChange([type, '=', null]);
        }
        else {
            onChange([null]);
        }
    };
};
var useAreaCityLabeledItems = function useAreaCityLabeledItems() {
    var _useSelector, _useSelector2;
    var cities = ((_useSelector = useSelector(function (state) {
        return state.cities.cities;
    })) === null || _useSelector === void 0 ? void 0 : _useSelector.cities) || [];
    var citiesMap = cities.reduce(function (map, city) {
        return _objectSpread(_objectSpread({}, map), {}, _defineProperty({}, city.id, city));
    }, {});
    var areas = ((_useSelector2 = useSelector(function (state) {
        return state.lookup.area;
    })) === null || _useSelector2 === void 0 ? void 0 : _useSelector2.items) || [];
    return areas.map(function (area) {
        return {
            id: area.id,
            name: "".concat(citiesMap[area.cityId].name, " - ").concat(area.name)
        };
    });
};
export var getComplexSelectorProperties = function getComplexSelectorProperties(selector) {
    var _useSelector3, _useSelector4, _useSelector5, _useSelector6, _useSelector7, _useSelector8, _useSelector9, _useSelector10, _useSelector11;
    var selectors = {
        complexStatusId: {
            dataSource: new DataSource({
                store: (_useSelector3 = useSelector(function (state) {
                    return state.lookup.complexStatus;
                })) === null || _useSelector3 === void 0 ? void 0 : _useSelector3.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'description',
            valueExpr: 'id'
        },
        catalogDeveloperId: {
            dataSource: new DataSource({
                store: (_useSelector4 = useSelector(function (state) {
                    return state.lookup.catalogDeveloper;
                })) === null || _useSelector4 === void 0 ? void 0 : _useSelector4.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogHousingClassId: {
            dataSource: new DataSource({
                store: (_useSelector5 = useSelector(function (state) {
                    return state.lookup.catalogHousingClass;
                })) === null || _useSelector5 === void 0 ? void 0 : _useSelector5.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        cityId: {
            dataSource: new DataSource({
                store: (_useSelector6 = useSelector(function (state) {
                    return state.cities.cities;
                })) === null || _useSelector6 === void 0 ? void 0 : _useSelector6.cities,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        areaId: {
            dataSource: new DataSource({
                store: useAreaCityLabeledItems(),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        municipalDistrictId: {
            dataSource: new DataSource({
                store: (_useSelector7 = useSelector(function (state) {
                    return state.lookup.municipalDistrict;
                })) === null || _useSelector7 === void 0 ? void 0 : _useSelector7.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'nameMunicipalDistrict',
            valueExpr: 'id'
        },
        catalogProtectionId: {
            dataSource: new DataSource({
                store: (_useSelector8 = useSelector(function (state) {
                    return state.lookup.catalogProtection;
                })) === null || _useSelector8 === void 0 ? void 0 : _useSelector8.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogSafetyPlaygroundsAndSportsFields: {
            dataSource: new DataSource({
                store: (_useSelector9 = useSelector(function (state) {
                    return state.lookup.catalogSafetyPlaygroundsAndSportsFields;
                })) === null || _useSelector9 === void 0 ? void 0 : _useSelector9.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogParkingType: {
            dataSource: new DataSource({
                store: (_useSelector10 = useSelector(function (state) {
                    return state.lookup.catalogParkingType;
                })) === null || _useSelector10 === void 0 ? void 0 : _useSelector10.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogPlaygroundsAndSportsFields: {
            dataSource: new DataSource({
                store: (_useSelector11 = useSelector(function (state) {
                    return state.lookup.catalogPlaygroundsAndSportsFields;
                })) === null || _useSelector11 === void 0 ? void 0 : _useSelector11.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogSimpleParking: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(PARKING_ACCESS_ID).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        catalogSimpleGreenwood: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(GREENWOOD_ID).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        developerNotInfo: {
            dataSource: new DataSource({
                store: complexDeveloperNotInfoStore,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'label',
            valueExpr: 'key'
        }
    };
    return selectors[selector];
};
export var getStageSelectorProperties = function getStageSelectorProperties(selector) {
    var _useSelector12, _useSelector13, _useSelector14, _useSelector15;
    var selectors = {
        complexStatusId: {
            dataSource: new DataSource({
                store: (_useSelector12 = useSelector(function (state) {
                    return state.lookup.complexStatus;
                })) === null || _useSelector12 === void 0 ? void 0 : _useSelector12.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'description',
            valueExpr: 'id'
        },
        catalogHousingClassId: {
            dataSource: new DataSource({
                store: (_useSelector13 = useSelector(function (state) {
                    return state.lookup.catalogHousingClass;
                })) === null || _useSelector13 === void 0 ? void 0 : _useSelector13.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogContractId: {
            dataSource: new DataSource({
                store: (_useSelector14 = useSelector(function (state) {
                    return state.lookup.catalogContract;
                })) === null || _useSelector14 === void 0 ? void 0 : _useSelector14.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        buildingStatus: {
            dataSource: new DataSource({
                store: (_useSelector15 = useSelector(function (state) {
                    return state.lookup.catalogBuildingStatus;
                })) === null || _useSelector15 === void 0 ? void 0 : _useSelector15.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        developerNotInfo: {
            dataSource: new DataSource({
                store: stageDeveloperNotInfoStore,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'label',
            valueExpr: 'key'
        }
    };
    return selectors[selector];
};
export var getBuildingSelectorProperties = function getBuildingSelectorProperties(selector) {
    var _useSelector16, _useSelector17, _useSelector18, _useSelector19, _useSelector20, _useSelector21, _useSelector22, _useSelector23;
    var selectors = {
        complexStatusId: {
            dataSource: new DataSource({
                store: (_useSelector16 = useSelector(function (state) {
                    return state.lookup.complexStatus;
                })) === null || _useSelector16 === void 0 ? void 0 : _useSelector16.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'description',
            valueExpr: 'id'
        },
        catalogTechnologyId: {
            dataSource: new DataSource({
                store: (_useSelector17 = useSelector(function (state) {
                    return state.lookup.catalogTechnology;
                })) === null || _useSelector17 === void 0 ? void 0 : _useSelector17.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogStrollersId: {
            dataSource: new DataSource({
                store: (_useSelector18 = useSelector(function (state) {
                    return state.lookup.catalogStrollers;
                })) === null || _useSelector18 === void 0 ? void 0 : _useSelector18.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogGlazedWindowsId: {
            dataSource: new DataSource({
                store: (_useSelector19 = useSelector(function (state) {
                    return state.lookup.catalogGlazedWindows;
                })) === null || _useSelector19 === void 0 ? void 0 : _useSelector19.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogBalconyGlazingId: {
            dataSource: new DataSource({
                store: (_useSelector20 = useSelector(function (state) {
                    return state.lookup.catalogBalconyGlazing;
                })) === null || _useSelector20 === void 0 ? void 0 : _useSelector20.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogSimpleEnergyEfficiency: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(SIMPLE_ITEMS_EN_EFF).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        catalogSimpleWindows: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(SIMPLE_ITEMS_WINDOWS).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        catalogSimpleMgnAccessibility: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(SIMPLE_ITEMS_MGN_ACCESSIBILITY).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        catalogSimpleBalcony: {
            dataSource: new DataSource({
                store: getArrayOfSImpleItemsByCatalog(SIMPLE_ITEMS_BALCONY_APPEARANCE).map(function (item) {
                    return {
                        name: item.name,
                        key: item.id
                    };
                }),
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'key'
        },
        catalogInputGroupEquipment: {
            dataSource: new DataSource({
                store: (_useSelector21 = useSelector(function (state) {
                    return state.lookup.catalogInputGroupEquipment;
                })) === null || _useSelector21 === void 0 ? void 0 : _useSelector21.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogFacadesCladding: {
            dataSource: new DataSource({
                store: (_useSelector22 = useSelector(function (state) {
                    return state.lookup.catalogFacadesCladding;
                })) === null || _useSelector22 === void 0 ? void 0 : _useSelector22.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        catalogFinish: {
            dataSource: new DataSource({
                store: (_useSelector23 = useSelector(function (state) {
                    return state.lookup.catalogFinish;
                })) === null || _useSelector23 === void 0 ? void 0 : _useSelector23.items,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'name',
            valueExpr: 'id'
        },
        developerNotInfo: {
            dataSource: new DataSource({
                store: buildingDeveloperNotInfoStore,
                paginate: true,
                pageSize: 10
            }),
            displayExpr: 'label',
            valueExpr: 'key'
        }
    };
    return selectors[selector];
};
var transform = function transform(s, labels) {
    var fields = s.split(',');
    var res = '';
    fields.forEach(function (f) {
        var _labels$find;
        var _f$split = f.split(' '), _f$split2 = _slicedToArray(_f$split, 2), name = _f$split2[0], type = _f$split2[1];
        var label = ((_labels$find = labels.find(function (l) {
            if (l.key === snakeCaseToCamelCase(name).trim())
                return true;
            return false;
        })) === null || _labels$find === void 0 ? void 0 : _labels$find.label) || '';
        if (!label) {
            console.log();
        }
        var tsType = '';
        switch (true) {
            case type.includes('serial'):
            case type.includes('int'):
                tsType = 'number';
                res += "@IntegerField({\n          label: '".concat(label, "',\n  })");
                break;
            case type.includes('varchar'):
                tsType = 'string';
                res += "@StringField({\n          label: '".concat(label, "',\n        })");
                break;
            case type.includes('timestamp'):
            case type.includes('date'):
                tsType = 'string';
                res += "@DateField({\n          label: '".concat(label, "',\n        })");
                break;
            case type.includes('bool'):
                tsType = 'boolean';
                res += "@BooleanField({\n          label: '".concat(label, "',\n        })");
                break;
            case type.includes('float'):
            case type.includes('numeric'):
                tsType = 'number';
                res += "@DecimalField({\n          label: '".concat(label, "',\n        })");
                break;
            case type.includes('jsonb'):
                tsType = 'string';
                res += "@JsonbField({\n          label: '".concat(label, "',\n        })");
                break;
        }
        res += "".concat(snakeCaseToCamelCase(name), ": ").concat(tsType, ";\n    \n    ");
    });
    console.log(res);
};
export { transform, onFilterKeyChange, onFilterOperatorChange, onFilterValueChange, onFilterComparatorOperatorChange, onFilterComparatorKeyChange, onSpecialFilterAggregationTypeChange };
