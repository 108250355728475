export var complexDeveloperNotInfoStore = [{
        key: 'name',
        label: 'Название'
    }, {
        key: 'mass_market',
        label: 'Масс-маркет'
    }, {
        key: 'house_ready',
        label: 'Дом сдан'
    }, {
        key: 'is_low_rise',
        label: 'Малоэтажный'
    }, {
        key: 'special_improvement_objects',
        label: 'Наличие особых объектов благоустройства'
    }, {
        key: 'places_for_adults',
        label: 'Места для отдыха взрослых'
    }, {
        key: 'commerce_square',
        label: 'Площади под коммерцию. Наличие'
    }, {
        key: 'is_cycle_parking',
        label: 'Велопарковка'
    }, {
        key: 'grounds_for_walking_dogs',
        label: 'Места для выгула собак'
    }, {
        key: 'address_text',
        label: 'Адрес'
    }, {
        key: 'park_distance',
        label: 'Расстояние до парковки'
    }, {
        key: 'park_distance_msk',
        label: 'Расстояние до парка. Москва'
    }, {
        key: 'is_closed_yard_cars',
        label: 'Закрытый от машин двор'
    }, {
        key: 'video_surveillance_system',
        label: 'Видеонаблюдение'
    }, {
        key: 'is_territory_enclosure',
        label: 'Ограждение/система контроля'
    }];
export var stageDeveloperNotInfoStore = [{
        key: 'building_permit_end',
        label: 'Дата окончания разрешения на строительство'
    }, {
        key: 'permit_fields',
        label: 'Разрешение на строительство'
    }, {
        key: 'project_declaration_files',
        label: 'Проектные декларации'
    }, {
        key: 'project_documentation_exam_files',
        label: 'Экспертиза проектной документации'
    }, {
        key: 'flats_total_cnt',
        label: 'Общее количество квартир'
    }, {
        key: 'count_flat_floor',
        label: 'Кол-во квартир на этаже'
    }, {
        key: 'q_studio_cnt',
        label: 'Количество студий'
    }, {
        key: 'q_one_room_apt',
        label: 'Количество 1 ккв'
    }, {
        key: 'q_two_room_apt',
        label: 'Количество 2 ккв'
    }, {
        key: 'q_three_room_apt',
        label: 'Количество 3 ккв'
    }, {
        key: 'q_four_room_apt',
        label: 'Количество 4 ккв'
    }, {
        key: 'deadline_end_year_auto',
        label: 'Срок сдачи (год)'
    }, {
        key: 'living_area_total_footage',
        label: 'Общая жилая площадь'
    }, {
        key: 'total_area',
        label: 'Общая площадь'
    }, {
        key: 'land_plot_area',
        label: 'Площадь земельного участка'
    }, {
        key: 'total_area_of_use',
        label: 'Площадь застройки'
    }, {
        key: 'paid_parking_spaces_cnt',
        label: 'Количество платных машиномест'
    }, {
        key: 'free_parking_spaces',
        label: 'Кол-во бесплатных машиномест'
    }, {
        key: 'kindergarten_cnt',
        label: 'Детский сад (в проекте)'
    }, {
        key: 'school_cnt',
        label: 'Школа (в проекте)'
    }];
export var buildingDeveloperNotInfoStore = [{
        key: 'is_housing',
        label: 'Жилье'
    }, {
        key: 'is_apartment',
        label: 'Апартаменты'
    }, {
        key: 'key_transfer_date',
        label: 'Дата передачи ключей'
    }, {
        key: 'storeys_num_from',
        label: 'Этажность, от'
    }, {
        key: 'storeys_num_to',
        label: 'Этажность, до'
    }, {
        key: 'ceiling_height_from',
        label: 'Высота потолков, от (м)'
    }, {
        key: 'ceiling_height_to',
        label: 'Высота потолков, до (м)'
    }, {
        key: 'latitude',
        label: 'Широта'
    }, {
        key: 'longitude',
        label: 'Долгота'
    }, {
        key: 'concierge',
        label: 'Консьерж'
    }, {
        key: 'pantry',
        label: 'Кладовые'
    }, {
        key: 'containers_split_system',
        label: 'Контейнеры для сплит-систем'
    }, {
        key: 'start_sale',
        label: 'Старт продаж'
    }, {
        key: 'living_area',
        label: 'Жилая площадь'
    }, {
        key: 'total_area',
        label: 'Общая площадь'
    }];
var allOperators = {
    equal: {
        operator: '=',
        label: '='
    },
    greaterThan: {
        operator: '>',
        label: '>'
    },
    lessThan: {
        operator: '<',
        label: '<'
    },
    notEqual: {
        operator: '<>',
        label: '<>'
    },
    greaterThanOrEqualTo: {
        operator: '>=',
        label: '>='
    },
    lessThanOrEqualTo: {
        operator: '<=',
        label: '<='
    },
    contains: {
        operator: 'contains',
        label: 'содержит'
    },
    notContains: {
        operator: 'notcontains',
        label: 'не содержит'
    }
};
export var booleanOperators = [allOperators.equal, allOperators.notEqual];
export var numberOperators = [allOperators.equal, allOperators.greaterThan, allOperators.greaterThanOrEqualTo, allOperators.lessThan, allOperators.lessThanOrEqualTo, allOperators.notEqual];
export var textOperators = [allOperators.equal, allOperators.notEqual, allOperators.contains, allOperators.notContains];
export var multiSelectOperators = [allOperators.equal];
