function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
var directionSymbols = {
    from: '>=',
    to: '<='
};
export var getDBFilterColumns = function getDBFilterColumns(filters, excluded, fields) {
    var result = {};
    Object.keys(filters).forEach(function (key) {
        var _key$split = key.split('_'), _key$split2 = _slicedToArray(_key$split, 2), property = _key$split2[0], direction = _key$split2[1];
        var field = fields.find(function (item) {
            return item.name === property;
        });
        if (field) {
            var type = field.type;
            var symbol = '=';
            if (excluded.includes(key))
                symbol = '!=';
            if (!excluded.includes(key) && directionSymbols[direction])
                symbol = directionSymbols[direction];
            if (!excluded.includes(key) && !directionSymbols[direction] && type === 'string')
                symbol = '~';
            var filterValue = /^\d{4}-\d{2}-\d{2}T/.test("".concat(filters[key])) ? "".concat(filters[key].toString().slice(0, 9).split('-').join('/'), " 00:00:00") : filters[key];
            if (result[property]) {
                result[property] = {
                    filterValue: [result[property].filterValue, filterValue],
                    selectedFilterOperation: 'between'
                };
            }
            else {
                result[property] = {
                    filterValue: filters[key],
                    selectedFilterOperation: symbol
                };
            }
        }
    });
    return result;
};
