export var filterItems = [{
        type: 'number',
        xs: 2,
        name: 'id',
        label: 'ID'
    }, {
        type: 'string',
        xs: 3,
        name: 'cityName',
        label: 'Город'
    }, {
        type: 'string',
        xs: 3,
        name: 'complexName',
        label: 'ЖК'
    }, {
        type: 'number',
        xs: 2,
        name: 'fillPercent',
        label: '% заполненности'
    }, {
        type: 'date',
        xs: 5,
        name: 'createAt',
        isInterval: true,
        labelFrom: 'Дата создания (от)',
        labelTo: 'Дата создания (до)'
    }, {
        type: 'check',
        xs: 2,
        name: 'noLayouts',
        label: 'Нет схем'
    }, {
        type: 'check',
        xs: 3,
        name: 'hasActiveApartments',
        label: 'Активные квартиры'
    }];
export var filterItemsResale = [{
        type: 'number',
        xs: 2,
        name: 'id',
        label: 'ID'
    }, {
        type: 'string',
        xs: 3,
        name: 'cityName',
        label: 'Город'
    }, {
        type: 'string',
        xs: 3,
        name: 'address',
        label: 'Адрес'
    }, {
        type: 'number',
        xs: 2,
        name: 'fillPercent',
        label: '% заполненности'
    }, {
        type: 'date',
        xs: 5,
        name: 'createAt',
        isInterval: true,
        labelFrom: 'Дата создания (от)',
        labelTo: 'Дата создания (до)'
    }, {
        type: 'check',
        xs: 3,
        name: 'hasActiveApartments',
        label: 'Активные квартиры'
    }, {
        type: 'check',
        xs: 1,
        name: 'noLayouts',
        label: 'Нет схем'
    }, {
        type: 'check',
        xs: 1,
        name: 'url',
        label: 'URL'
    }];
