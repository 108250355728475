export var SET_BUILDINGS_DATAGRID = 'SET_BUILDINGS_DATAGRID';
export var SET_STAGES_DATAGRID = 'SET_STAGES_DATAGRID';
export var SET_COMPLEXES_DATAGRID = 'SET_COMPLEXES_DATAGRID';
export var SET_APARTMENT_ADS_DATAGRID = 'SET_APARTMENT_ADS_DATAGRID';
export var SET_DRAFT_COMPLEXES_DATAGRID = 'SET_DRAFT_COMPLEXES_DATAGRID';
export var SET_DRAFT_BUILDINGS_DATAGRID = 'SET_DRAFT_BUILDINGS_DATAGRID';
export var SET_AD_TAGS_DATAGRID = 'SET_AD_TAGS_DATAGRID';
export var SET_AD_TAG_GROUPS_DATAGRID = 'SET_AD_TAG_GROUPS_DATAGRID';
export var SET_PLANNINGS_DATAGRID = 'SET_PLANNINGS_DATAGRID';
export var SET_RESALE_PLANNINGS_DATAGRID = 'SET_RESALE_PLANNINGS_DATAGRID';
export var SET_BANK_PROGRAMS_DATAGRID = 'SET_BANK_PROGRAMS_DATAGRID';
export var SET_BANK_PROGRAM_DATAGRID = 'SET_BANK_PROGRAM_DATAGRID';
export var SET_DEVELOPER_DATAGRID = 'SET_DEVELOPER_DATAGRID';
export var SET_DATAGRID_PAGE = 'SET_DATAGRID_PAGE';
