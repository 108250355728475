import { BANKS_FILTER, BANKS_FILTER_EXCLUDED, BANKS_SORT, BANKS_PAGE, BANKS_PAGE_SIZE } from '../constants/bank';
export var setBanksFilter = function setBanksFilter(state) {
    return function (dispatch) {
        dispatch({
            type: BANKS_FILTER,
            payload: state
        });
    };
};
export var setBanksFilterExcluded = function setBanksFilterExcluded(state) {
    return function (dispatch) {
        dispatch({
            type: BANKS_FILTER_EXCLUDED,
            payload: state
        });
    };
};
export var setBanksSort = function setBanksSort(state) {
    return function (dispatch) {
        dispatch({
            type: BANKS_SORT,
            payload: state
        });
    };
};
export var setBanksPage = function setBanksPage(state) {
    return function (dispatch) {
        dispatch({
            type: BANKS_PAGE,
            payload: state
        });
    };
};
export var setBanksPageSize = function setBanksPageSize(state) {
    return function (dispatch) {
        dispatch({
            type: BANKS_PAGE_SIZE,
            payload: state
        });
    };
};
