function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { FAIL, START, STOP, SUCCESS, BUILDING_GET_BUILDING, BUILDING_GET_COMPLEX, BUILDING_GET_SIMPLE_ITEMS, BUILDING_GET_INPUT_GROUP_EQUIPMENT, BUILDING_GET_FINISH, BUILDING_GET_FACADES_CLADDING, BUILDING_GET_MATVIEW, BUILDING_SET_ACTIVE_TAB, BUILDING_GET_STAGE, BUILDING_RESET_FIELDS } from '../../constants';
var initialState = {
    building: null,
    simpleItems: null,
    complex: null,
    stage: null,
    inputGroupEquipments: null,
    finishes: null,
    facadesCladdings: null,
    loading: false,
    loaded: false,
    matView: null,
    activeTab: 0
};
var building = function building() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var type = action.type, payload = action.payload;
    switch (type) {
        case BUILDING_RESET_FIELDS:
            return initialState;
        case BUILDING_SET_ACTIVE_TAB:
            return _objectSpread(_objectSpread({}, state), {}, {
                activeTab: payload
            });
        case BUILDING_GET_BUILDING + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                loaded: false
            });
        case BUILDING_GET_BUILDING + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                building: payload,
                loaded: true
            });
        case BUILDING_GET_BUILDING + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                building: null
            });
        case BUILDING_GET_BUILDING + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false
            });
        case BUILDING_GET_COMPLEX + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complex: payload,
                loaded: true
            });
        case BUILDING_GET_COMPLEX + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complex: null,
                loaded: false
            });
        case BUILDING_GET_STAGE + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                stage: payload,
                loaded: true
            });
        case BUILDING_GET_STAGE + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                stage: null,
                loaded: false
            });
        case BUILDING_GET_SIMPLE_ITEMS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleItems: payload,
                loaded: true
            });
        case BUILDING_GET_SIMPLE_ITEMS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleItems: null,
                loaded: false
            });
        case BUILDING_GET_INPUT_GROUP_EQUIPMENT + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                inputGroupEquipments: payload,
                loaded: true
            });
        case BUILDING_GET_INPUT_GROUP_EQUIPMENT + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                inputGroupEquipments: null,
                loaded: false
            });
        case BUILDING_GET_FINISH + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                finishes: payload,
                loaded: true
            });
        case BUILDING_GET_FINISH + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                finishes: null,
                loaded: false
            });
        case BUILDING_GET_FACADES_CLADDING + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                facadesCladdings: payload,
                loaded: true
            });
        case BUILDING_GET_FACADES_CLADDING + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                facadesCladdings: null,
                loaded: false
            });
        case BUILDING_GET_MATVIEW + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                matView: payload,
                loaded: true
            });
        case BUILDING_GET_MATVIEW + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                matView: null,
                loaded: false
            });
        default:
            return state;
    }
};
export default building;
