import { RESALE_PLANNINGS_FILTER, RESALE_PLANNINGS_FILTER_EXCLUDED } from './_action.types';
export var setResalePlanningsFilter = function setResalePlanningsFilter(state) {
    return function (dispatch) {
        dispatch({
            type: RESALE_PLANNINGS_FILTER,
            payload: state
        });
    };
};
export var setResalePlanningsFilterExcluded = function setResalePlanningsFilterExcluded(state) {
    return function (dispatch) {
        dispatch({
            type: RESALE_PLANNINGS_FILTER_EXCLUDED,
            payload: state
        });
    };
};
