// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/clarikapro-grotesque-heavy-fsp.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'ClarikaProGrotesque';\n  font-style: normal;\n  font-weight: 700;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n\nbody {\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n  color: #333;\n    \n  & h1, h2, h3, h4, h5 {\n    color: #000;\n  }\n}\n    \n\n& #back-office-app {\n  background-color: #fff;\n}\n\n& .grecaptcha-badge {\n  display: none;\n}", "",{"version":3,"sources":["webpack://./src/client/app/styles/index.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2DAA4E;AAC9E;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,WAAW;;EAEX;IACE,WAAW;EACb;AACF;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@font-face {\n  font-family: 'ClarikaProGrotesque';\n  font-style: normal;\n  font-weight: 700;\n  font-display: swap;\n  src: url('/public/fonts/clarikapro-grotesque-heavy-fsp.woff') format('woff');\n}\n\nbody {\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n  color: #333;\n    \n  & h1, h2, h3, h4, h5 {\n    color: #000;\n  }\n}\n    \n\n& #back-office-app {\n  background-color: #fff;\n}\n\n& .grecaptcha-badge {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
