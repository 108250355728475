import styled from 'styled-components';
import { DropDownBox } from 'devextreme-react';
export var TabsWrapper = styled.div.withConfig({
    displayName: "styles__TabsWrapper",
    componentId: "sc-1sfs4o9-0"
})(["display:flex;flex-grow:1;min-height:100%;padding:15px 0 0 0;"]);
export var TabContentWrapper = styled.div.withConfig({
    displayName: "styles__TabContentWrapper",
    componentId: "sc-1sfs4o9-1"
})(["display:flex;flex-direction:column;min-height:100%;padding:15px 5px 35px 25px;box-sizing:border-box;border-left:1px solid rgba(0,0,0,0.12);"]);
export var TabPanelRoot = styled.div.withConfig({
    displayName: "styles__TabPanelRoot",
    componentId: "sc-1sfs4o9-2"
})(["width:100%;"]);
export var ChiperRoot = styled.div.withConfig({
    displayName: "styles__ChiperRoot",
    componentId: "sc-1sfs4o9-3"
})(["display:flex;flex-direction:column;width:100%;& > div:first-child{margin-bottom:10px;}"]);
export var ChipSelectorRoot = styled.div.withConfig({
    displayName: "styles__ChipSelectorRoot",
    componentId: "sc-1sfs4o9-4"
})(["display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;"]);
export var ReadOnlyRoot = styled.div.withConfig({
    displayName: "styles__ReadOnlyRoot",
    componentId: "sc-1sfs4o9-5"
})(["display:flex;align-items:center;justify-content:flex-start;width:100%;height:100%;"]);
export var ReadOnlyValueWrapper = styled.span.withConfig({
    displayName: "styles__ReadOnlyValueWrapper",
    componentId: "sc-1sfs4o9-6"
})(["padding-left:5px;color:rgba(0,0,0,0.8);"]);
export var MainPhotoRoot = styled.div.withConfig({
    displayName: "styles__MainPhotoRoot",
    componentId: "sc-1sfs4o9-7"
})(["display:grid;grid-gap:10px;grid-template-areas:'header header' 'thumb values';grid-template-columns:130px 1fr;"]);
export var MainPhotoThumbWrapper = styled.div.withConfig({
    displayName: "styles__MainPhotoThumbWrapper",
    componentId: "sc-1sfs4o9-8"
})(["display:flex;align-items:center;justify-content:center;width:130px;height:130px;position:relative;grid-area:thumb;box-sizing:border-box;border:1px solid #0000001f;border-radius:5px;"]);
export var MainPhotoThumbLink = styled.a.withConfig({
    displayName: "styles__MainPhotoThumbLink",
    componentId: "sc-1sfs4o9-9"
})(["display:block;text-decoration:none;&:hover,&:visited{color:inherit;}"]);
export var MainPhotoThumbnail = styled.img.withConfig({
    displayName: "styles__MainPhotoThumbnail",
    componentId: "sc-1sfs4o9-10"
})(["width:auto;height:auto;max-width:100px;max-height:100px;"]);
export var MainPhotosIconButtonWrapper = styled.div.withConfig({
    displayName: "styles__MainPhotosIconButtonWrapper",
    componentId: "sc-1sfs4o9-11"
})(["position:absolute;bottom:5px;right:5px;"]);
export var MainPhotoUploaderRoot = styled.div.withConfig({
    displayName: "styles__MainPhotoUploaderRoot",
    componentId: "sc-1sfs4o9-12"
})(["display:flex;flex-direction:column;grid-area:values;border:1px solid #0000001f;border-radius:5px;& .dx-fileuploader-input-wrapper{height:100%;}"]);
export var MorePhotoRoot = styled.div.withConfig({
    displayName: "styles__MorePhotoRoot",
    componentId: "sc-1sfs4o9-13"
})(["display:grid;grid-gap:10px;grid-template-areas:'title title' 'thumbnails loader';grid-template-columns:410px 1fr;"]);
export var MorePhotoThumbnailsRoot = styled.div.withConfig({
    displayName: "styles__MorePhotoThumbnailsRoot",
    componentId: "sc-1sfs4o9-14"
})(["display:grid;grid-gap:10px;grid-template-columns:repeat(3,130px);grid-area:thumbnails;"]);
export var MorePhotoThumbWrapper = styled.div.withConfig({
    displayName: "styles__MorePhotoThumbWrapper",
    componentId: "sc-1sfs4o9-15"
})(["display:flex;align-items:center;justify-content:center;position:relative;width:130px;height:130px;box-sizing:border-box;border:1px solid ", ";border-radius:5px;"], function (props) {
    return props.added ? '#1976d28c' : '#0000001f';
});
export var MorePhotoThumbLink = styled.a.withConfig({
    displayName: "styles__MorePhotoThumbLink",
    componentId: "sc-1sfs4o9-16"
})(["display:block;text-decoration:none;&:hover,&:visited{color:inherit;}"]);
export var MorePhotoThumbnail = styled.img.withConfig({
    displayName: "styles__MorePhotoThumbnail",
    componentId: "sc-1sfs4o9-17"
})(["width:auto;height:auto;max-width:100px;max-height:100px;"]);
export var MorePhotoUploaderRoot = styled.div.withConfig({
    displayName: "styles__MorePhotoUploaderRoot",
    componentId: "sc-1sfs4o9-18"
})(["display:flex;flex-direction:column;grid-area:loader;border:1px solid #0000001f;border-radius:5px;& .dx-fileuploader-input-wrapper{height:100%;}"]);
export var MorePhotosIconButtonWrapper = styled.div.withConfig({
    displayName: "styles__MorePhotosIconButtonWrapper",
    componentId: "sc-1sfs4o9-19"
})(["position:absolute;bottom:5px;right:5px;"]);
export var AlertWrapper = styled.div.withConfig({
    displayName: "styles__AlertWrapper",
    componentId: "sc-1sfs4o9-20"
})(["position:fixed;bottom:20px;right:145px;box-shadow:0 0 2px 1px #0000001d;"]);
export var XDropDownBox = styled(DropDownBox).withConfig({
    displayName: "styles__XDropDownBox",
    componentId: "sc-1sfs4o9-21"
})(["height:52px;& .dx-texteditor-input{font-size:16px;}& .dx-texteditor-label .dx-label span{font-size:12px;}& .dx-dropdowneditor-icon:before{top:73%;font-size:24px;}&.dx-dropdowneditor-active .dx-dropdowneditor-icon::before{top:73%;font-size:24px;}"]);
