export var defaultNewBankData = {
    bankLogo: null
};
export var BANK_SET_LOGO = 'BANK_SET_LOGO';
export var BANK_SAVE_LOGO = 'BANK_SAVE_LOGO';
export var BANKS_FILTER = 'BANKS_FILTER';
export var BANKS_FILTER_EXCLUDED = 'BANKS_FILTER_EXCLUDED';
export var BANKS_SORT = 'BANKS_SORT';
export var BANKS_PAGE = 'BANKS_PAGE';
export var BANKS_PAGE_SIZE = 'BANKS_PAGE_SIZE';
