function calculateFilterExpressionArrayColumn(filterValue, selectedFilterOperation) {
    var column = this;
    if (filterValue) {
        var selector = function selector(data) {
            var applyOperation = function applyOperation(arg1, arg2, op) {
                switch (op) {
                    case 'endswith':
                        return arg1.endsWith(arg2);
                    case 'startswith':
                        return arg1.startsWith(arg2);
                    case 'contains':
                        return arg1.includes(arg2);
                    case '=':
                    default:
                        return arg1 === arg2;
                }
            };
            var values = column.calculateCellValue(data);
            return values && !!(values !== null && values !== void 0 && values.find(function (v) {
                return applyOperation(v, filterValue, selectedFilterOperation);
            }));
        };
        return [selector, '=', true];
    }
    /* eslint-disable-next-line */
    return this.defaultCalculateFilterExpression.apply(this, arguments);
}
export default calculateFilterExpressionArrayColumn;
