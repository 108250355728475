import { queryOptions } from '@tanstack/react-query';
import { http } from '@/api';
import { EP, ep } from '@/api/endpoints';
import { DOMAIN } from '@/consts';
var cacheKey = DOMAIN.APARTMENT_AD;
export var getApartmentAdQueryOptions = function getApartmentAdQueryOptions(id, token) {
    return queryOptions({
        queryKey: [cacheKey, id],
        queryFn: function queryFn() {
            return http.get({
                url: ep(EP.APARTMENT_AD, {
                    id: id
                }),
                token: token
            }).then(function (_ref) {
                var data = _ref.data;
                return data;
            });
        }
    });
};
