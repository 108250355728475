import { Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
export var AppPreloaderBox = styled.div.withConfig({
    displayName: "styles__AppPreloaderBox",
    componentId: "sc-wfveit-0"
})(["user-select:none;"]);
export var PagePreloaderBox = styled.div.withConfig({
    displayName: "styles__PagePreloaderBox",
    componentId: "sc-wfveit-1"
})(["position:sticky;top:40%;height:100%;display:flex;flex-direction:column;justify-content:center;user-select:none;"]);
export var DCLabel = styled.div.withConfig({
    displayName: "styles__DCLabel",
    componentId: "sc-wfveit-2"
})(["margin-left:-2px;font-weight:700;font-size:26px;color:#890077;"]);
export var LoadingStatus = styled(Typography).attrs({
    variant: 'caption'
}).withConfig({
    displayName: "styles__LoadingStatus",
    componentId: "sc-wfveit-3"
})(["position:absolute;bottom:128px;left:50%;transform:translateX(-50%);font-variant:small-caps;text-transform:uppercase;font-weight:bold;color:#333333;"]);
var dotsFlashing = keyframes(["0%{visibility:hidden;}25%,100%{visibility:visible;}"]);
var dotFlashing = keyframes(["0%{content:\"\";}50%,100%{content:\".\";}"]);
export var FlashingDots = styled.div.withConfig({
    displayName: "styles__FlashingDots",
    componentId: "sc-wfveit-4"
})(["position:absolute;bottom:0;left:100%;span{font-weight:bold;animation:", " 2s infinite step-end;}span::before,span::after{content:'';}span::before{animation:", " 2s infinite step-end;}span::after{animation:", " 2s infinite step-end;animation-delay:0.5s;}"], dotsFlashing, dotFlashing, dotFlashing);
