export var roleModules = [{
        name: 'Комплексы',
        module: 'complexes'
    }, {
        name: 'Комплексы удаление',
        module: 'complexes_deleting'
    }, {
        name: 'Очереди',
        module: 'stages'
    }, {
        name: 'Корпуса',
        module: 'buildings'
    }, {
        name: 'Отсутствующие объекты',
        module: 'draft'
    }, {
        name: 'Модерация',
        module: 'moderation'
    }, {
        name: 'Теги',
        module: 'tags'
    }, {
        name: 'Банковские программы',
        module: 'bank-programs'
    }, {
        name: 'Планировки',
        module: 'plannings'
    }, {
        name: 'Застройщики',
        module: 'developers'
    }, {
        name: 'Отчеты',
        module: 'logs'
    }, {
        name: 'Справочники',
        module: 'handbooks'
    }, {
        name: 'Экспорт',
        module: 'export'
    }];
