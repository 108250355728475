import styled from 'styled-components';
export var TabsWrapper = styled.div.withConfig({
    displayName: "styles__TabsWrapper",
    componentId: "sc-e102xw-0"
})(["display:flex;flex-grow:1;min-height:100%;padding:15px 0;"]);
export var TabContentWrapper = styled.div.withConfig({
    displayName: "styles__TabContentWrapper",
    componentId: "sc-e102xw-1"
})(["display:flex;flex-direction:column;min-height:100%;padding:15px 5px 15px 25px;box-sizing:border-box;border-left:1px solid rgba(0,0,0,0.12);"]);
export var TabPanelRoot = styled.div.withConfig({
    displayName: "styles__TabPanelRoot",
    componentId: "sc-e102xw-2"
})(["width:100%;"]);
export var AlertWrapper = styled.div.withConfig({
    displayName: "styles__AlertWrapper",
    componentId: "sc-e102xw-3"
})(["position:fixed;bottom:20px;right:80px;box-shadow:0 0 2px 1px #0000001d;"]);
