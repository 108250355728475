function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { COMPLEX_ADD_ALIASES_ACTOR, COMPLEX_DELETE_ALIASES_ACTOR, COMPLEX_GET_COMPLEX, COMPLEX_LOADING_ALIASES, COMPLEX_LOADING_PARKING_TYPES, COMPLEX_LOADING_SIMPLE_COMPLEX, COMPLEX_SET_COMPLEX, FAIL, START, STOP, SUCCESS, COMPLEX_LOADING_PROBLEMS, COMPLEX_LOADING_METRO, COMPLEX_LOADING_PHOTOS, COMPLEX_SET_MAIN_PHOTO, COMPLEX_ADD_PHOTO_ACTOR, COMPLEX_DELETE_PHOTO_ACTOR, COMPLEX_LOADING_PLAYGROUNDS, COMPLEX_LOADING_SAFETY_PLAYGROUNDS, COMPLEX_ADD_PLAYGROUNDS_ACTOR, COMPLEX_DELETE_PLAYGROUNDS_ACTOR, COMPLEX_ADD_SAFETY_PLAYGROUNDS_ACTOR, COMPLEX_DELETE_SAFETY_PLAYGROUNDS_ACTOR, COMPLEX_ADD_PARKING_TYPE_ACTOR, COMPLEX_DELETE_PARKING_TYPE_ACTOR, COMPLEX_LOADING_INFRASTRUCTURE_AUTO, COMPLEX_ADD_METRO_ACTOR, COMPLEX_DELETE_METRO_ACTOR, COMPLEX_GET_NEW_COMPLEX_DATA, COMPLEX_UPDATE_COMPLEX_DATA, defaultNewComplexData, COMPLEX_NEED_UPDATE_DATA, COMPLEX_UPDATE_PROCESS, COMPLEX_SET_COMPLEX_PROBLEM_ACTORS, COMPLEX_SET_SIMPLE_COMPLEX_ACTORS } from '../../constants';
import { addActor, deleteActor } from '../../utilities/actors';
var initialState = {
    complex: null,
    changedComplex: {},
    aliases: null,
    aliasesActors: {
        added: [],
        deleted: []
    },
    parkingTypes: null,
    parkingTypesActors: {
        added: [],
        deleted: []
    },
    simpleComplex: null,
    simpleComplexActors: {
        added: [],
        deleted: []
    },
    complexProblems: null,
    complexProblemsActors: {
        added: [],
        deleted: []
    },
    complexMetro: null,
    complexMetroActors: {
        added: [],
        deleted: []
    },
    complexMainPhoto: '',
    complexPhotos: null,
    complexPhotosActors: {
        added: [],
        deleted: []
    },
    complexInfrastructureAuto: null,
    complexPlaygrounds: null,
    complexPlaygroundActors: {
        added: [],
        deleted: []
    },
    complexSafetyPlaygrounds: null,
    complexSafetyPlaygroundsActors: {
        added: [],
        deleted: []
    },
    needUpdateAction: false,
    savingProgress: false,
    saving: false,
    saved: false,
    savingError: false,
    loading: false,
    loaded: false
};
var defaultSuccessData = {
    aliasesActors: _objectSpread({}, initialState.aliasesActors),
    parkingTypesActors: _objectSpread({}, initialState.parkingTypesActors),
    simpleComplexActors: _objectSpread({}, initialState.simpleComplexActors),
    complexProblemsActors: _objectSpread({}, initialState.complexProblemsActors),
    complexMetroActors: _objectSpread({}, initialState.complexMetroActors),
    complexMainPhoto: '',
    complexPhotosActors: _objectSpread({}, initialState.complexPhotosActors),
    complexPlaygroundActors: _objectSpread({}, initialState.complexPlaygroundActors),
    complexSafetyPlaygroundsActors: _objectSpread({}, initialState.complexSafetyPlaygroundsActors),
    changedComplex: {},
    needUpdateAction: false,
    saving: false,
    saved: false,
    savingError: false
};
var defaultNewData = {
    aliases: {
        count: 0,
        aliases: []
    },
    parkingTypes: {
        count: 0,
        parkingTypes: []
    },
    simpleComplex: {
        count: 0,
        items: []
    },
    complexProblems: {
        count: 0,
        problems: []
    },
    complexMetro: {
        count: 0,
        metro: []
    },
    complexMainPhoto: '',
    complexPhotos: {
        count: 0,
        photos: []
    },
    complexPlaygrounds: {
        count: 0,
        items: []
    },
    complexSafetyPlaygrounds: {
        count: 0,
        items: []
    },
    complexInfrastructureAuto: {
        count: 0,
        items: []
    }
};
var complex = function complex() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case COMPLEX_GET_COMPLEX + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                loaded: false
            });
        case COMPLEX_GET_COMPLEX + SUCCESS:
            return _objectSpread(_objectSpread(_objectSpread({}, state), {}, {
                complex: action.payload
            }, defaultSuccessData), {}, {
                loaded: true
            });
        case COMPLEX_GET_NEW_COMPLEX_DATA:
            return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, state), {}, {
                complex: _objectSpread({}, defaultNewComplexData)
            }, defaultNewData), defaultSuccessData), {}, {
                loaded: true
            });
        case COMPLEX_GET_COMPLEX + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                complex: null
            });
        case COMPLEX_GET_COMPLEX + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false
            });
        case COMPLEX_NEED_UPDATE_DATA:
            return _objectSpread(_objectSpread({}, state), {}, {
                needUpdateAction: true,
                savingProgress: true
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                needUpdateAction: false,
                saving: true,
                saved: false,
                savingError: false
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                saving: false,
                saved: true,
                savingError: false
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                saving: false,
                saved: false,
                savingError: true
            });
        case COMPLEX_UPDATE_PROCESS + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                savingProgress: false,
                saved: false,
                savingError: false
            });
        case COMPLEX_LOADING_ALIASES + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                aliases: _objectSpread({}, action.payload)
            });
        case COMPLEX_LOADING_ALIASES + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                aliases: null
            });
        case COMPLEX_LOADING_PARKING_TYPES + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                parkingTypes: _objectSpread({}, action.payload)
            });
        case COMPLEX_LOADING_PARKING_TYPES + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                parkingTypes: null
            });
        case COMPLEX_ADD_ALIASES_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                aliasesActors: addActor(state, action.payload, 'aliasesActors', 'name')
            });
        case COMPLEX_DELETE_ALIASES_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                aliasesActors: deleteActor(state, action.payload, 'aliasesActors', 'name')
            });
        case COMPLEX_SET_SIMPLE_COMPLEX_ACTORS:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleComplexActors: action.payload
            });
        case COMPLEX_LOADING_SIMPLE_COMPLEX + START:
            return state;
        case COMPLEX_LOADING_SIMPLE_COMPLEX + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleComplex: action.payload
            });
        case COMPLEX_LOADING_SIMPLE_COMPLEX + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleComplex: null
            });
        case COMPLEX_LOADING_PROBLEMS + START:
            return state;
        case COMPLEX_LOADING_PROBLEMS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexProblems: action.payload
            });
        case COMPLEX_LOADING_PROBLEMS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexProblems: null
            });
        case COMPLEX_SET_COMPLEX_PROBLEM_ACTORS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexProblemsActors: action.payload
            });
        case COMPLEX_LOADING_METRO + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexMetro: action.payload
            });
        case COMPLEX_LOADING_METRO + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexMetro: null
            });
        case COMPLEX_ADD_METRO_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexMetroActors: addActor(state, action.payload, 'complexMetroActors', 'metroId')
            });
        case COMPLEX_DELETE_METRO_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexMetroActors: deleteActor(state, action.payload, 'complexMetroActors', 'metroId')
            });
        case COMPLEX_LOADING_PHOTOS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPhotos: action.payload
            });
        case COMPLEX_LOADING_PHOTOS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPhotos: null
            });
        case COMPLEX_SET_MAIN_PHOTO:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexMainPhoto: action.payload
            });
        case COMPLEX_ADD_PHOTO_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPhotosActors: _objectSpread(_objectSpread({}, state.complexPhotosActors), {}, {
                    added: [].concat(_toConsumableArray(state.complexPhotosActors.added), [action.payload])
                })
            });
        case COMPLEX_DELETE_PHOTO_ACTOR:
            {
                var id = action.payload.id;
                return _objectSpread(_objectSpread({}, state), {}, {
                    complexPhotosActors: {
                        added: id ? state.complexPhotosActors.added : state.complexPhotosActors.added.filter(function (photo) {
                            return photo.name !== action.payload.name;
                        }),
                        deleted: id ? [].concat(_toConsumableArray(state.complexPhotosActors.deleted), [action.payload]) : state.complexPhotosActors.deleted
                    }
                });
            }
        case COMPLEX_LOADING_INFRASTRUCTURE_AUTO + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexInfrastructureAuto: action.payload
            });
        case COMPLEX_LOADING_INFRASTRUCTURE_AUTO + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexInfrastructureAuto: null
            });
        case COMPLEX_LOADING_PLAYGROUNDS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPlaygrounds: action.payload
            });
        case COMPLEX_LOADING_PLAYGROUNDS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPlaygrounds: null
            });
        case COMPLEX_ADD_PLAYGROUNDS_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPlaygroundActors: addActor(state, action.payload, 'complexPlaygroundActors')
            });
        case COMPLEX_DELETE_PLAYGROUNDS_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexPlaygroundActors: deleteActor(state, action.payload, 'complexPlaygroundActors', 'catalogPlaygroundsAndSportsFieldsId')
            });
        case COMPLEX_LOADING_SAFETY_PLAYGROUNDS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexSafetyPlaygrounds: action.payload
            });
        case COMPLEX_LOADING_SAFETY_PLAYGROUNDS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexSafetyPlaygrounds: null
            });
        case COMPLEX_ADD_SAFETY_PLAYGROUNDS_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexSafetyPlaygroundsActors: addActor(state, action.payload, 'complexSafetyPlaygroundsActors')
            });
        case COMPLEX_DELETE_SAFETY_PLAYGROUNDS_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                complexSafetyPlaygroundsActors: deleteActor(state, action.payload, 'complexSafetyPlaygroundsActors', 'catalogSafetyPlaygroundsAndSportsFieldsId')
            });
        case COMPLEX_ADD_PARKING_TYPE_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                parkingTypesActors: addActor(state, action.payload, 'parkingTypesActors')
            });
        case COMPLEX_DELETE_PARKING_TYPE_ACTOR:
            return _objectSpread(_objectSpread({}, state), {}, {
                parkingTypesActors: deleteActor(state, action.payload, 'parkingTypesActors', 'catalogParkingTypeId')
            });
        case COMPLEX_SET_COMPLEX:
            {
                var payload = action.payload;
                var changedValues = _objectSpread(_objectSpread({}, state.changedComplex), payload);
                var newValues = Object.keys(changedValues).reduce(function (acc, key) {
                    return changedValues[key] === state.complex[key] ? acc : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, changedValues[key]));
                }, {});
                return _objectSpread(_objectSpread({}, state), {}, {
                    changedComplex: _objectSpread({}, newValues)
                });
            }
        default:
            return state;
    }
};
export default complex;
