import { PLANNINGS_FILTER, PLANNINGS_FILTER_EXCLUDED } from './_action.types';
export var setPlanningsFilter = function setPlanningsFilter(state) {
    return function (dispatch) {
        dispatch({
            type: PLANNINGS_FILTER,
            payload: state
        });
    };
};
export var setPlanningsFilterExcluded = function setPlanningsFilterExcluded(state) {
    return function (dispatch) {
        dispatch({
            type: PLANNINGS_FILTER_EXCLUDED,
            payload: state
        });
    };
};
