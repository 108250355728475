import { PLANNING_SET_NEXT_ID, PLANNING_SET_PLANNING, PLANNING_SET_PREV_ID } from './_action.types';
export var setPlanning = function setPlanning(data) {
    return function (dispatch) {
        dispatch({
            type: PLANNING_SET_PLANNING,
            payload: data
        });
    };
};
export var setPlanningPrevId = function setPlanningPrevId(payload) {
    return {
        type: PLANNING_SET_PREV_ID,
        payload: payload
    };
};
export var setPlanningNextId = function setPlanningNextId(payload) {
    return {
        type: PLANNING_SET_NEXT_ID,
        payload: payload
    };
};
