function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
var getIsIntervalsCorrect = function getIsIntervalsCorrect(data, intervals, enqueueSnackbar) {
    var isAllCorrect = true;
    intervals.forEach(function (_ref) {
        var title = _ref.title, _ref$interval = _slicedToArray(_ref.interval, 2), from = _ref$interval[0], to = _ref$interval[1];
        if (!!data[from] && !!data[to] && +data[from] > +data[to]) {
            enqueueSnackbar("\u041E\u0448\u0438\u0431\u043A\u0430 \u0432 \u0438\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u0435 ".concat(title), {
                variant: 'error'
            });
            isAllCorrect = false;
        }
    });
    return isAllCorrect;
};
export default getIsIntervalsCorrect;
