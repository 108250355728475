function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
export var useRebuildNotification = function useRebuildNotification(socket) {
    var _useSnackbar = useSnackbar(), enqueueSnackbar = _useSnackbar.enqueueSnackbar, closeSnackbar = _useSnackbar.closeSnackbar;
    var _useState = useState(), _useState2 = _slicedToArray(_useState, 2), isInit = _useState2[0], setIsInit = _useState2[1];
    var _useState3 = useState(null), _useState4 = _slicedToArray(_useState3, 2), notificationId = _useState4[0], setNotificationId = _useState4[1];
    useEffect(function () {
        if (socket && socket.connected && !isInit) {
            socket.emit('rebuild:ready');
            socket.on('rebuild:info', function (data) {
                closeSnackbar();
                var id = enqueueSnackbar({
                    duration: data.duration,
                    info: data.info,
                    variant: 'rebuild',
                    persist: true
                });
                setNotificationId(id);
            });
            socket.on('rebuild:abort', function (data) {
                closeSnackbar();
                enqueueSnackbar({
                    message: data.reason,
                    variant: 'info'
                });
            });
            socket.on('rebuild:success', function () {
                closeSnackbar();
                enqueueSnackbar({
                    message: 'Сервер был перезагружен. Для продолжения работы обновите страницу',
                    variant: 'info',
                    persist: true
                });
            });
            setIsInit(true);
        }
    }, [socket, notificationId]);
};
