import { useSelector } from '@/hooks';
var getArrayOfSImpleItemsByCatalog = function getArrayOfSImpleItemsByCatalog(catalogId) {
    var itemIdsByCatalogId = useSelector(function (state) {
        var _state$lookup$catalog;
        return ((_state$lookup$catalog = state.lookup.catalogSimpleToItem) === null || _state$lookup$catalog === void 0 ? void 0 : _state$lookup$catalog.items) || [];
    }).filter(function (item) {
        return item.catalogId === catalogId;
    }).map(function (item) {
        return item.itemId;
    });
    return useSelector(function (state) {
        var _state$lookup$catalog2;
        return ((_state$lookup$catalog2 = state.lookup.catalogSimpleItem) === null || _state$lookup$catalog2 === void 0 ? void 0 : _state$lookup$catalog2.items) || [];
    }).filter(function (item) {
        return itemIdsByCatalogId.includes(item.id);
    });
};
export default getArrayOfSImpleItemsByCatalog;
