import { strToBase64 } from '@/utils/transform/base64';
// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require('buffer').Buffer;
var shimmer = function shimmer(w, h, light) {
    return "\n<svg width=\"".concat(w, "\" height=\"").concat(h, "\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n  <defs>\n    <linearGradient id=\"g\">\n      <stop stop-color=\"").concat(light ? '#efedf0' : '#352e44', "\" offset=\"20%\" />\n      <stop stop-color=\"").concat(light ? '#d8d7da' : '#1f1b2d', "\" offset=\"50%\" />\n      <stop stop-color=\"").concat(light ? '#efedf0' : '#352e44', "\" offset=\"70%\" />\n    </linearGradient>\n  </defs>\n  <rect width=\"").concat(w, "\" height=\"").concat(h, "\" fill=\"").concat(light ? '#efedf0' : '#352e44', "\" />\n  <rect id=\"r\" width=\"").concat(w, "\" height=\"").concat(h, "\" fill=\"url(#g)\" />\n  <animate xlink:href=\"#r\" attributeName=\"x\" from=\"-").concat(w, "\" to=\"").concat(w, "\" dur=\"1s\" repeatCount=\"indefinite\"  />\n</svg>");
};
var getImgPlaceholder = function getImgPlaceholder(width, height, theme) {
    if (!width || !height) {
        return undefined;
    }
    var light = true;
    if (theme) {
        light = theme === 'light';
    }
    return "data:image/svg+xml;base64,".concat(strToBase64(shimmer(width, height, light)));
};
export { getImgPlaceholder };
