import { SIBLING_SLIDE_STATUS } from '@/consts';
export var initialState = {
    data: null,
    prevItem: {
        id: null,
        status: SIBLING_SLIDE_STATUS.NOT_INITIALIZED
    },
    nextItem: {
        id: null,
        status: SIBLING_SLIDE_STATUS.NOT_INITIALIZED
    }
};
