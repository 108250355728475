var actionsDataSource = [{
        key: 'delete',
        label: 'Удаление'
    }, {
        key: 'update',
        label: 'Обновление'
    }, {
        key: 'create',
        label: 'Создание'
    }];
export default actionsDataSource;
