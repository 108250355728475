export var realityFieldsWeights = [{
        key: 1,
        label: 'Менее важно'
    }, {
        key: 2,
        label: 'Важно'
    }, {
        key: 3,
        label: 'Очень важно'
    }];
