function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
import { COLOR_PALETTE } from '@/consts';
/**
 * Сравнивает поля двух объектов и выводит совпадающие ключи
 * @param {Object} obj1
 * @param {Object} obj2
 * @param {number} accuracy (for numbers)
 * @return {string}
 */
export var compareObjects = function compareObjects(obj1, obj2) {
    var accuracy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var keyName = Object.keys(obj1).find(function (key) {
        return Object.keys(obj2).includes(key);
    });
    if (!keyName) {
        return {
            key: false,
            isSimilar: false
        };
    }
    var param1 = obj1[keyName];
    var param2 = obj2[keyName];
    if (param1 && param2) {
        switch (keyName) {
            case 'address':
                {
                    return {
                        key: keyName,
                        isSimilar: param1.trim() === param2.trim()
                    };
                }
            case 'areaTotal':
            case 'areaKitchen':
            case 'areaResidential':
                {
                    var diff = Math.round((Number(param1) - Number(param2)) * 100) / 100;
                    if (Math.abs(Number(param1) - Number(param2)) <= accuracy) {
                        return {
                            key: keyName,
                            diff: diff,
                            isSimilar: true
                        };
                    }
                    return {
                        key: keyName,
                        diff: diff,
                        isSimilar: false
                    };
                }
            case 'roomsCnt':
                {
                    var _map = [param1, param2].map(function (param) {
                        return "".concat(param).toLowerCase() === 'студия' ? 0 : param;
                    }), _map2 = _slicedToArray(_map, 2), formattedParam1 = _map2[0], formattedParam2 = _map2[1];
                    var _diff = Math.round((Number(formattedParam1) - Number(formattedParam2)) * 100) / 100;
                    if (Math.abs(Number(formattedParam1) - Number(formattedParam2)) <= accuracy) {
                        return {
                            key: keyName,
                            diff: _diff,
                            isSimilar: true
                        };
                    }
                    return {
                        key: keyName,
                        diff: _diff,
                        isSimilar: false
                    };
                }
            case 'price':
            case 'floor':
                {
                    var _diff2 = Math.round((Number(param1) - Number(param2)) * 100) / 100;
                    if (Math.abs(Number(param1) - Number(param2)) <= accuracy) {
                        return {
                            key: keyName,
                            diff: _diff2,
                            isSimilar: true
                        };
                    }
                    return {
                        key: keyName,
                        diff: _diff2,
                        isSimilar: false
                    };
                }
            default:
                return {
                    key: false,
                    isSimilar: false
                };
        }
    }
    if (!param1 && !param2) {
        return {
            key: keyName,
            isSimilar: true,
            isNoData: true
        };
    }
    return {
        key: false,
        isSimilar: false
    };
};
export var getComparisonResultColor = function getComparisonResultColor(isSimilar, hasComparables) {
    if (!hasComparables) {
        return COLOR_PALETTE.NOT_ACTIVE;
    }
    if (isSimilar) {
        return COLOR_PALETTE.SUCCESS;
    }
    return COLOR_PALETTE.ERROR;
};
