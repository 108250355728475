export var filterItems = [{
        type: 'number',
        xs: 2,
        name: 'id',
        label: 'ID'
    }, {
        type: 'string',
        xs: 6,
        name: 'name',
        label: 'Название'
    }, {
        type: 'check',
        xs: 2,
        name: 'deleted',
        label: 'Удален'
    }];
export var columnsSort = [{
        name: 'ID ⬇',
        value: 'id_desc'
    }, {
        name: 'ID ⬆',
        value: 'id_asc'
    }, {
        name: 'Название ⬇',
        value: 'name_desc'
    }, {
        name: 'Название ⬆',
        value: 'name_asc'
    }];
