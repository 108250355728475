function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { FAIL, STAGE_GET_BANKS, STAGE_GET_COMPLEX, STAGE_GET_DEADLINE, STAGE_GET_PERMIT, STAGE_GET_PROBLEM, STAGE_GET_PROJECT_DECLARATION, STAGE_GET_PROJECT_DOCUMENTATION_EXAM, STAGE_GET_SIMPLE_ITEMS, STAGE_GET_STAGE, STAGE_GET_SUBSIDIES, STAGE_RESET_FIELDS, STAGE_SET_ACTIVE_TAB, START, STOP, SUCCESS } from '../../constants';
var initialState = {
    stage: null,
    complex: null,
    problem: null,
    problemActors: {
        added: [],
        deleted: []
    },
    deadline: null,
    buildingPermits: null,
    projectDeclarations: null,
    projectDocumentationExams: null,
    subsidies: null,
    banks: null,
    simpleItems: null,
    loading: false,
    loaded: false,
    activeTab: 0
};
var stage = function stage() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var type = action.type, payload = action.payload;
    switch (type) {
        case STAGE_RESET_FIELDS:
            return initialState;
        case STAGE_SET_ACTIVE_TAB:
            return _objectSpread(_objectSpread({}, state), {}, {
                activeTab: payload
            });
        case STAGE_GET_STAGE + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                loaded: false
            });
        case STAGE_GET_STAGE + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                stage: payload,
                loaded: true
            });
        case STAGE_GET_STAGE + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                stage: null
            });
        case STAGE_GET_STAGE + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false
            });
        case STAGE_GET_COMPLEX + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                complex: payload,
                loaded: true
            });
        case STAGE_GET_COMPLEX + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                complex: null
            });
        case STAGE_GET_PROBLEM + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                problem: payload,
                loaded: true
            });
        case STAGE_GET_PROBLEM + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                problem: null
            });
        case STAGE_GET_DEADLINE + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                deadline: payload,
                loaded: true
            });
        case STAGE_GET_DEADLINE + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                deadline: null
            });
        case STAGE_GET_PERMIT + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                buildingPermits: payload,
                loaded: true
            });
        case STAGE_GET_PERMIT + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                buildingPermits: null
            });
        case STAGE_GET_PROJECT_DECLARATION + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                projectDeclarations: payload,
                loaded: true
            });
        case STAGE_GET_PROJECT_DECLARATION + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                projectDeclarations: null
            });
        case STAGE_GET_PROJECT_DOCUMENTATION_EXAM + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                projectDocumentationExams: payload,
                loaded: true
            });
        case STAGE_GET_PROJECT_DOCUMENTATION_EXAM + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                projectDocumentationExams: null
            });
        case STAGE_GET_SUBSIDIES + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                subsidies: payload,
                loaded: true
            });
        case STAGE_GET_SUBSIDIES + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                subsidies: null
            });
        case STAGE_GET_BANKS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                banks: payload,
                loaded: true
            });
        case STAGE_GET_BANKS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                banks: null
            });
        case STAGE_GET_SIMPLE_ITEMS + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                simpleItems: payload,
                loaded: true
            });
        case STAGE_GET_SIMPLE_ITEMS + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                simpleItems: null
            });
        default:
            return state;
    }
};
export default stage;
