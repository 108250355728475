// domain entities names
// use camelCase values
export var DOMAIN = {
    COMPLEX: 'complex',
    STAGE: 'stage',
    BUILDING: 'building',
    DEVELOPER: 'developer',
    APARTMENT_AD: 'apartmentAd',
    AD_TAG: 'adTag',
    AD_TAG_GROUP: 'adTagGroup',
    PLANNING: 'planning',
    RESALE_PLANNING: 'resalePlanning',
    COMMENT: 'comment'
};
export var DOMAIN_PLURAL = {
    COMPLEX: 'complexes',
    STAGE: 'stages',
    BUILDING: 'buildings',
    APARTMENT_AD: 'apartmentAds',
    AD_TAG: 'adTags',
    AD_TAG_GROUP: 'adTagGroups',
    DEVELOPER: 'developers',
    PLANNING: 'plannings',
    RESALE_PLANNING: 'resalePlannings',
    COMMENT: 'comments'
};
