function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { DOMAIN_PLURAL } from '@/consts';
import { LISTS_SET_LIST, LISTS_SET_LIST_SIBLING_PAGE, LISTS_SWAP_SIBLING_PAGE_TO_CURRENT } from '../../constants/list';
var initialState = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DOMAIN_PLURAL.COMPLEX, null), DOMAIN_PLURAL.STAGE, null), DOMAIN_PLURAL.BUILDING, null), DOMAIN_PLURAL.APARTMENT_AD, null), DOMAIN_PLURAL.PLANNING, null), DOMAIN_PLURAL.RESALE_PLANNING, null);
var lists = function lists() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var type = action.type, payload = action.payload;
    switch (type) {
        case LISTS_SET_LIST:
            return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, payload.listId, _objectSpread(_objectSpread({}, payload), {}, {
                isLoaded: true,
                isLoading: false
            })));
        case LISTS_SET_LIST_SIBLING_PAGE:
            return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, payload.listId, _objectSpread(_objectSpread({}, state[payload.listId]), {}, {
                siblingPage: {
                    items: payload.items,
                    direction: payload.direction
                }
            })));
        case LISTS_SWAP_SIBLING_PAGE_TO_CURRENT:
            return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, payload.listId, _objectSpread(_objectSpread({}, state[payload.listId]), {}, {
                items: payload.items,
                siblingPage: payload.siblingPage
            })));
        default:
            return state;
    }
};
export { lists };
