var planningTypes = {
    id: 'number',
    complexId: 'number',
    seriesId: 'number',
    houseId: 'number',
    roomsCnt: 'number',
    areaTotal: 'number',
    areaTotalMin: 'number',
    areaTotalMax: 'number',
    areaResidential: 'number',
    areaResidentialMin: 'number',
    areaResidentialMax: 'number',
    areaKitchen: 'number',
    areaKitchenMin: 'number',
    areaKitchenMax: 'number',
    layout: 'string',
    balcony: 'string',
    bathroom: 'string',
    balconyId: 'number',
    bathroomId: 'number',
    internalId: 'number',
    nicheCorridorCnt: 'number',
    nicheRoomCnt: 'number',
    nicheKitchenCnt: 'number',
    pantryCorridorCnt: 'number',
    pantryRoomCnt: 'number',
    laundryCnt: 'number',
    roomWithBathroomCnt: 'number',
    hasSauna: 'boolean',
    hasMultipleWindowKitchen: 'boolean',
    hasMultipleWindowRoom: 'boolean',
    isEuroPlanning: 'boolean',
    isFreePlanning: 'boolean',
    isDuplex: 'boolean',
    hasWindowBathroom: 'boolean',
    isOffStandardPlanning: 'boolean',
    loggiaKitchenCnt: 'number',
    loggiaRoomCnt: 'number',
    loggiaAdjacentCnt: 'number',
    hasLargeGlazingArea: 'boolean',
    isPenthouse: 'boolean',
    isDoubleSidedApartment: 'boolean',
    isOneSidedApartment: 'boolean',
    isNarrowApartment: 'boolean',
    isCornerApartment: 'boolean',
    hasIsolatedRooms: 'boolean',
    hasAdjacentRooms: 'boolean',
    isReplanningAvailable: 'boolean',
    fillPercent: 'number',
    isRoomZoningAvailable: 'boolean',
    balconyAdjacentRoomCnt: 'number',
    balconyAdjacentKitchenCnt: 'number',
    balconyAdjacentKitchenAndRoomCnt: 'number',
    shaftKitchenCnt: 'number',
    shaftRoomCnt: 'number',
    noLayouts: 'boolean',
    isEditCompleted: 'boolean',
    areaCorridor: 'number',
    isSmallBathroom: 'boolean',
    typeCorridor: 'string',
    roomsCntEditable: 'number',
    isSingleCorridorArea: 'boolean',
    isEffectiveAreaCorridor: 'boolean',
    fillPercentTemp1: 'number',
    createAt: 'string',
    updateAt: 'string',
    lastCheckLayouts: 'string'
};
export default planningTypes;
