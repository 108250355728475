function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
export var addActor = function addActor(state, item, actorField, choiceField) {
    var _ref;
    var id = item.id;
    var notAdded = choiceField ? state[actorField].added.findIndex(function (el) {
        return el[choiceField] === item[choiceField];
    }) === -1 : true;
    return {
        added: id ? state[actorField].added : [].concat(_toConsumableArray(state[actorField].added), _toConsumableArray(notAdded ? [item] : [])),
        deleted: id ? (_ref = _toConsumableArray(state[actorField].deleted.filter(function (el) {
            return el.id !== id;
        }))) !== null && _ref !== void 0 ? _ref : [] : state[actorField].deleted
    };
};
export var deleteActor = function deleteActor(state, item, actorField, choiceField) {
    var _ref2;
    var id = item.id;
    return {
        added: id ? state[actorField].added : (_ref2 = _toConsumableArray(state[actorField].added.filter(function (el) {
            return el[choiceField] !== item[choiceField];
        }))) !== null && _ref2 !== void 0 ? _ref2 : [],
        deleted: id ? [].concat(_toConsumableArray(state[actorField].deleted), [item]) : state[actorField].deleted
    };
};
