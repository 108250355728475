function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
function getFilteredData(unfilteredData, filters, dateKeys) {
    var excludedFields = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var sort = arguments.length > 4 ? arguments[4] : undefined;
    if (isEqual(filters, {}))
        return sort ? orderBy.apply(void 0, [unfilteredData].concat(_toConsumableArray(sort.split('_')))) : unfilteredData;
    var res = unfilteredData;
    var dayInSeconds = 86400000;
    var dateKeysIsInterval = dateKeys.length === 2 && isEqual(['from', 'to'], dateKeys.map(function (item) {
        return item.split('_')[1];
    }));
    if (dateKeysIsInterval && filters[dateKeys[0]] && filters[dateKeys[1]]) {
        var _ref = [filters[dateKeys[0]], filters[dateKeys[1]], dateKeys[0].split('_')[0]], from = _ref[0], to = _ref[1], dateKey = _ref[2];
        res = res.filter(function (item) {
            return new Date(item[dateKey]).getTime() >= new Date(from).getTime() && new Date(item[dateKey]).getTime() <= new Date(new Date(to).valueOf() + dayInSeconds).getTime();
        });
        filters = omit(filters, dateKeys);
    }
    var _loop = function _loop(key) {
        res = res.filter(function (item) {
            if (item[key] === null || item[key] === undefined)
                return false;
            var isExcluded = excludedFields.indexOf(key) === -1;
            if (typeof filters[key] === 'number')
                return excludedFields.indexOf(key) === -1 ? +item[key] === filters[key] : +item[key] !== filters[key];
            if (dateKeys.indexOf(key) !== -1 && !dateKeysIsInterval) {
                if (filters[key] instanceof Date)
                    return excludedFields.indexOf(key) === -1 ? item[key].indexOf(new Date(filters[key].valueOf() + dayInSeconds).toISOString().split('T')[0]) > -1 : item[key].indexOf(new Date(filters[key].valueOf() + dayInSeconds).toISOString().split('T')[0]) === -1;
                return excludedFields.indexOf(key) === -1 ? item[key].indexOf(new Date(new Date(filters[key]).valueOf() + dayInSeconds).toISOString().split('T')[0]) > -1 : item[key].indexOf(new Date(new Date(filters[key]).valueOf() + dayInSeconds).toISOString().split('T')[0]) === -1;
            }
            return excludedFields.indexOf(key) === -1 ? item[key].toString().toLowerCase().indexOf(filters[key].toString().toLowerCase()) > -1 : item[key].toString().toLowerCase().indexOf(filters[key].toString().toLowerCase()) === -1;
        });
    };
    for (var key in filters) {
        _loop(key);
    }
    return sort ? orderBy.apply(void 0, [res].concat(_toConsumableArray(sort.split('_')))) : res;
}
export default getFilteredData;
