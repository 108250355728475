export var municipalDistrictForm = [{
        title: 'Муниципальный район',
        number: 0
    }, {
        title: 'Городское поселение',
        number: 1
    }, {
        title: 'Сельское поселение',
        number: 2
    }, {
        title: 'Городской округ',
        number: 3
    }, {
        title: 'Муниципальное образование',
        number: 4
    }, {
        title: 'Город',
        number: 5
    }, {
        title: 'Микрорайон',
        number: 6
    }, {
        title: 'Муниципальный округ',
        number: 7
    }];
