function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
export var makeDataSource = function makeDataSource(data, selected, keyField) {
    var idField = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
    var nameField = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'name';
    return data.map(function (item) {
        return {
            id: item[idField],
            name: item[nameField],
            added: selected.findIndex(function (el) {
                return el[keyField] === item[idField];
            }) === -1
        };
    });
};
export var makeSelected = function makeSelected(data, dataSource, actors, keyField) {
    return [].concat(_toConsumableArray(data.reduce(function (acc, curr) {
        var item = dataSource.find(function (el) {
            return el.id === curr[keyField];
        });
        var deletedIndex = actors.deleted.findIndex(function (el) {
            return el[keyField] === curr[keyField];
        });
        return !!item && deletedIndex === -1 ? [].concat(_toConsumableArray(acc), [item]) : acc;
    }, [])), _toConsumableArray(actors.added.map(function (item) {
        return dataSource.find(function (el) {
            return el.id === item[keyField];
        });
    })));
};
