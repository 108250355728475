export var SLIDE_DIRECTION = {
    NEXT: 1,
    PREV: -1
};
export var SIBLING_SLIDE_STATUS = {
    NOT_INITIALIZED: 'Not initialized',
    NOT_AVAILABLE: 'N/A',
    NOT_EXISTS: 'Not exists',
    LOADING: 'Loading',
    READY: 'Ready'
};
