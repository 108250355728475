import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { planning } from '@/entities/planning';
import { plannings } from '@/entities/plannings';
import { resalePlanning } from '@/entities/resalePlanning';
import { resalePlannings } from '@/entities/resalePlannings';
import apartmentAd from '../reducers/apartmentAd/apartmentAd';
import bank from '../reducers/bank/bank';
import banks from '../reducers/banks/banks';
import building from '../reducers/building/building';
import cities from '../reducers/cities/cities';
import city from '../reducers/city/city';
import complex from '../reducers/complex/complex';
import complexes from '../reducers/complexes/complexes';
import condition from '../reducers/condition/condition';
import datagrid from '../reducers/datagrid/datagrid';
import exportModels from '../reducers/exportModels/exportModels';
import handbook from '../reducers/handbook/handbook';
import { lists } from '../reducers/lists/lists';
import login from '../reducers/login/login';
import lookup from '../reducers/lookup/lookup';
import roles from '../reducers/roles/roles';
import stage from '../reducers/stage/stage';
import tabs from '../reducers/tabs/tabs';
import user from '../reducers/user/user';
import users from '../reducers/users/users';
var createStoreWithMiddleware = __DEV__ ? composeWithDevTools(applyMiddleware(thunk))(createStore) : applyMiddleware(thunk)(createStore);
var rootReducer = combineReducers({
    login: login,
    user: user,
    roles: roles,
    users: users,
    complex: complex,
    complexes: complexes,
    lists: lists,
    lookup: lookup,
    exportModels: exportModels,
    city: city,
    cities: cities,
    planning: planning,
    plannings: plannings,
    resalePlannings: resalePlannings,
    resalePlanning: resalePlanning,
    tabs: tabs,
    stage: stage,
    building: building,
    datagrid: datagrid,
    apartmentAd: apartmentAd,
    bank: bank,
    banks: banks,
    condition: condition,
    handbook: handbook
});
export var store = createStoreWithMiddleware(rootReducer);
