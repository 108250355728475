function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"])
    return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r))
    return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
export var isNotEmpty = function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '' && typeof value !== 'boolean';
};
export var onlyDigitsPhone = function onlyDigitsPhone(phone) {
    return phone.replace(/\D+/g, '');
};
/**
 * Получение параметра объекта из массива объектов по ключевому параметру
 * @param {string} key - имя ключа
 * @param {*} keyValue - значение ключа для поиска
 * @param {string} name - имя параметра
 * @param {*[]} source - массив объектов в котором осуществляется поиск
 * @return {*|undefined}
 */
export var getByKey = function getByKey(key, keyValue, name, source) {
    var _source$find$name;
    return (_source$find$name = source.find(function (item) {
        return item[key] === keyValue;
    })[name]) !== null && _source$find$name !== void 0 ? _source$find$name : undefined;
};
/**
 * Определяет возможность доступа текущего пользователя к действиям ограниченным ролями (правами доступа)
 * @param {string|string[]} validRoles - Роль или роли имеющие доступ к текущей операции
 * @param {TUserState} user - текущий пользователь
 * @return {boolean}
 */
export var currentUserCan = function currentUserCan(validRoles, user) {
    if (!Array.isArray(validRoles) && validRoles === 'all')
        return true;
    return Array.isArray(validRoles) ? (user === null || user === void 0 ? void 0 : user.role) && validRoles.includes(user === null || user === void 0 ? void 0 : user.role) : (user === null || user === void 0 ? void 0 : user.role) === validRoles;
};
/**
 * Определяет возможность доступа текущего пользователя к действиям ограниченным ролями (правами доступа)
 * @param {string} validModule - текущий модуль
 * @param {TUserState} user - текущий пользователь
 * @return {boolean}
 */
export var currentUserCanbyModule = function currentUserCanbyModule(validModule, user) {
    if (!user.modules)
        return false;
    if (user.modules === 'all' || user.role === 'admin')
        return true;
    return user.modules.includes(validModule);
};
/**
 * Конвертация имени параметра из kebab-case в camelCase
 * @param {string} name
 * @returns {string}
 */
export var kebabCaseToCamelCase = function kebabCaseToCamelCase() {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return name.replace(/(-\w)/g, function (m) {
        return m[1].toUpperCase();
    });
};
/**
 * Конвертация имени параметра из snake_case в camelCase
 * @param {string} name
 * @returns {string}
 */
export var snakeCaseToCamelCase = function snakeCaseToCamelCase() {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return name.replace(/(_\w)/g, function (m) {
        return m[1].toUpperCase();
    });
};
export var findById = function findById() {
    var _data$find$key;
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var id = arguments.length > 1 ? arguments[1] : undefined;
    var key = arguments.length > 2 ? arguments[2] : undefined;
    return !!id && key ? (_data$find$key = data.find(function (item) {
        return item.id === id;
    })[key]) !== null && _data$find$key !== void 0 ? _data$find$key : null : null;
};
export var findIdByKey = function findIdByKey() {
    var _data$find$id;
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var key = arguments.length > 1 ? arguments[1] : undefined;
    var value = arguments.length > 2 ? arguments[2] : undefined;
    return key ? (_data$find$id = data.find(function (item) {
        return item[key] === value;
    }).id) !== null && _data$find$id !== void 0 ? _data$find$id : null : null;
};
export var extractDifferenceByKey = function extractDifferenceByKey(first, second, key) {
    return first.reduce(function (acc, curr) {
        return second.findIndex(function (item) {
            return item[key] === curr[key];
        }) === -1 ? [].concat(_toConsumableArray(acc), [curr]) : acc;
    }, []);
};
/**
 * Удаление поля объекта по ключу. Поскольку метод delete запрещён к применению для удаления поля объекта
 * используем "танцы с бубном".
 * @param obj <Object> - исходный объект
 * @param key <string> - ключ поля которое нужно удалить
 * @returns {string|*|{[p: string]: *}}
 */
export var removeKey = function removeKey(obj, key) {
    return Object.keys(obj).reduce(function (acc, curr) {
        return curr !== key ? _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, curr, obj[curr])) : acc;
    }, {});
};
/**
 * Являются ли сравниваемые объекты эквивалентными
 * @param {Object} template - первый объект
 * @param {Object} source - второй объект
 * @return {boolean}
 */
export var isEqualObjects = function isEqualObjects(template, source) {
    if (template === null && source !== null)
        return false;
    if (template !== null && source === null)
        return false;
    if (template === null && source === null)
        return true;
    if (Object.keys(template).length === Object.keys(source).length) {
        return Object.keys(template).reduce(function (acc, curr) {
            return template[curr] === source[curr] && acc;
        }, true);
    }
    return false;
};
/**
 * Является ли объект пустым объектом
 * @param {Object} obj
 * @return {boolean}
 */
export var isEmpty = function isEmpty(obj) {
    return !obj ? true : !Object.keys(obj).length;
};
export var fixNullValues = function fixNullValues(obj) {
    var result = {};
    Object.keys(obj).forEach(function (key) {
        if (obj[key] === null) {
            result[key] = '';
        }
        else {
            result[key] = obj[key];
        }
    });
    return result;
};
export var addIfNotExists = function addIfNotExists(source, item, key) {
    var isExists = source.findIndex(function (el) {
        return el[key] === item[key];
    }) !== -1;
    return isExists ? source : [].concat(_toConsumableArray(source), [item]);
};
// ** Document Cookie ** START **
export var setCookie = function setCookie(name, value) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var opts = _objectSpread({
        path: '/'
    }, options);
    if (options.expires instanceof Date) {
        opts.expires = options.expires.toUTCString();
    }
    var cookieOptions = Object.keys(opts).map(function (key) {
        return "; ".concat(key).concat(opts[key] !== true ? "=".concat(opts[key]) : '');
    }).join('');
    document.cookie = "".concat(encodeURIComponent(name), "=").concat(encodeURIComponent(value)).concat(cookieOptions);
};
// ** Document Cookie ** START **
