import styled from 'styled-components';
export var LogoRoot = styled.div.withConfig({
    displayName: "styles__LogoRoot",
    componentId: "sc-dp4hlc-0"
})(["display:grid;grid-gap:10px;grid-template-areas:'header header' 'thumb values';grid-template-columns:130px 1fr;"]);
export var LogoThumbWrapper = styled.div.withConfig({
    displayName: "styles__LogoThumbWrapper",
    componentId: "sc-dp4hlc-1"
})(["display:flex;align-items:center;justify-content:center;width:130px;height:130px;position:relative;grid-area:thumb;box-sizing:border-box;border:1px solid #0000001f;border-radius:5px;"]);
export var LogoThumbLink = styled.a.withConfig({
    displayName: "styles__LogoThumbLink",
    componentId: "sc-dp4hlc-2"
})(["display:block;text-decoration:none;&:hover,&:visited{color:inherit;}"]);
export var LogoThumbnail = styled.img.withConfig({
    displayName: "styles__LogoThumbnail",
    componentId: "sc-dp4hlc-3"
})(["width:auto;height:auto;max-width:100px;max-height:100px;"]);
export var LogosIconButtonWrapper = styled.div.withConfig({
    displayName: "styles__LogosIconButtonWrapper",
    componentId: "sc-dp4hlc-4"
})(["position:absolute;bottom:5px;right:5px;"]);
export var LogoUploaderRoot = styled.div.withConfig({
    displayName: "styles__LogoUploaderRoot",
    componentId: "sc-dp4hlc-5"
})(["display:flex;flex-direction:column;grid-area:values;border:1px solid #0000001f;border-radius:5px;& .dx-fileuploader-input-wrapper{height:100%;}"]);
