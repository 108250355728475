function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { FAIL, LOGIN_SET_LOGIN, LOGIN_SET_NEED_LOGIN, LOGIN_STAGE_ONE, LOGIN_STAGE_TWO, START, SUCCESS } from '../../constants';
var initialState = {
    stage: 'phone',
    baseToken: '',
    code: '',
    needLogin: false,
    reCaptchaToken: '',
    reCaptchaTime: null,
    loading: false,
    loaded: false,
    fail: false
};
var login = function login() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case LOGIN_SET_LOGIN:
            return _objectSpread(_objectSpread({}, state), action.payload);
        case LOGIN_SET_NEED_LOGIN:
            return _objectSpread(_objectSpread({}, state), {}, {
                needLogin: action.payload
            });
        case LOGIN_STAGE_ONE + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                fail: false
            });
        case LOGIN_STAGE_ONE + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false,
                baseToken: action.payload,
                stage: 'code'
            });
        case LOGIN_STAGE_ONE + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false,
                baseToken: '',
                fail: true
            });
        case LOGIN_STAGE_TWO + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                fail: false
            });
        case LOGIN_STAGE_TWO + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false,
                stage: 'phone',
                baseToken: '',
                code: '',
                needLogin: false
            });
        case LOGIN_STAGE_TWO + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false,
                fail: true
            });
        default:
            return state;
    }
};
export default login;
