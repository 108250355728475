export var USER_SET_USER = 'USER_SET_USER';
export var USER_RESET_USER = 'USER_RESET_USER';
export var USER_LOADING_USER = 'USER_LOADING_USER';
export var USER_SET_ROLES = 'USER_SET_ROLES';
export var USER_LOADING_ROLES = 'USER_LOADING_ROLES';
export var USER_GET_ROLE = 'USER_GET_ROLE';
export var USER_UPDATE_ROLE = 'USER_UPDATE_ROLE';
export var USER_INSERT_ROLE = 'USER_INSERT_ROLE';
export var USER_DELETE_ROLE = 'USER_DELETE_ROLE';
export var USER_LOADING_USERS = 'USER_LOADING_USERS';
export var USER_GET_USER = 'USER_GET_USER';
export var USER_UPDATE_USER = 'USER_UPDATE_USER';
export var USER_INSERT_USER = 'USER_INSERT_USER';
export var USER_DELETE_USER = 'USER_DELETE_USER';
