import styled from 'styled-components';
export var Header = styled.header.withConfig({
    displayName: "styles__Header",
    componentId: "sc-1deavc2-0"
})(["display:flex;justify-content:space-between;align-items:center;width:100%;margin-block:20px;"]);
export var actionsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '20px'
};
