export var ep = function ep(endpoint, params, queryParams) {
    if (/\/:[-_\w]+/g.test(endpoint) && !params) {
        throw new Error('Client API: Не переданы параметры для создания динамического URL');
    }
    var finalEndpoint = endpoint;
    Object.keys(params || {}).forEach(function (param) {
        finalEndpoint = finalEndpoint.replaceAll(new RegExp(":".concat(param), 'g'), params[param]);
    });
    if (/\/:[-_\w]+/g.test(finalEndpoint)) {
        throw new Error('Client API: Параметры не соответствуют шаблону динамического URL');
    }
    Object.keys(queryParams || {}).forEach(function (queryParam, i) {
        if (queryParams[queryParam]) {
            finalEndpoint = "".concat(finalEndpoint).concat(i === 0 ? '?' : '&').concat(queryParam, "=").concat(queryParams[queryParam]);
        }
    });
    return finalEndpoint;
};
// all endpoints map
export var EP = {
    APARTMENT_AD: '/data/apartment-ads/moderation/:id',
    PLANNINGS: '/data/apartment-plannings',
    PLANNING: '/data/apartment-planning/:id',
    PLANNING_LAYOUTS: '/data/apartment-planning/:id/layout',
    RESALE_PLANNINGS: '/data/resale-apartment-plannings',
    RESALE_PLANNING: '/data/resale-apartment-planning/:id',
    RESALE_PLANNING_LAYOUTS: '/data/resale-apartment-planning/:id/layouts',
    RESALE_PLANNING_ADS: '/data/resale-apartment-planning/:id/ads',
    COMMENTS: '/data/comments',
    COMMENT: '/data/comment',
    COMMENT_ID: '/data/comment/:id',
    HOME_PAGE_STATS: '/data/page/home'
};
